import React, {useEffect, useState} from 'react';
import {CaratType, MetalTooltipType} from "../../types";

const UseMetalColorTooltip = () => {
    const MetalDetails: MetalTooltipType = {
        'yellow-gold': [
            {
                id: '18k',
                url: 'https://www.helzberg.com/on/demandware.static/-/Library-Sites-helzberg-content/default/dw6b95936a/images/metal-composition/yellow-18k.svg'
            },
            {
                id: '14k',
                url: 'https://www.helzberg.com/on/demandware.static/-/Library-Sites-helzberg-content/default/dwb3f8eb8d/images/metal-composition/yellow-14k.svg'
            }
        ],
        'white-gold': [
            {
                id: '18k',
                url: 'https://www.helzberg.com/on/demandware.static/-/Library-Sites-helzberg-content/default/dw83c00ebc/images/metal-composition/white-18k.svg'
            },
            {
                id: '14k',
                url: 'https://www.helzberg.com/on/demandware.static/-/Library-Sites-helzberg-content/default/dwe68f0d63/images/metal-composition/white-14k.svg'
            }
        ],
        'rose-gold': [
            {
                id: '18k',
                url: 'https://www.helzberg.com/on/demandware.static/-/Library-Sites-helzberg-content/default/dw978e2c55/images/metal-composition/rose-18k.svg'
            },
            {
                id: '14k',
                url: 'https://www.helzberg.com/on/demandware.static/-/Library-Sites-helzberg-content/default/dw4088fd51/images/metal-composition/rose-14k.svg'
            }
        ],
        'platinum': [
            {
                id: 'platinum',
                url: 'https://www.helzberg.com/on/demandware.static/-/Library-Sites-helzberg-content/default/dw2ba4cbfc/images/metal-composition/platinum.svg'
            },
        ]
    };
    const swatchColor :{[key: string ] : string} = {
        'yellow-gold': 'https://www.helzberg.com/on/demandware.static/Sites-Helzberg-Site/-/en_US/images/swatches/yellow.svg',
        'white-gold': 'https://www.helzberg.com/on/demandware.static/Sites-Helzberg-Site/-/en_US/images/swatches/white.svg',
        'rose-gold': 'https://www.helzberg.com/on/demandware.static/Sites-Helzberg-Site/-/en_US/images/swatches/rose.svg',
        'platinum': 'https://www.helzberg.com/on/demandware.static/Sites-Helzberg-Site/-/en_US/images/swatches/platinum.svg',
    }
    const [selectedMetal, setSelectedMetal] = useState<string>('yellow-gold');
    const [selectedCarat, setSelectedCarat] = useState<string>('14k');
    const [metalCarat, setMetalCarat] = useState<CaratType[]>([]);
    const [selectedUrl, setSelectedUrl] = useState<string>('');

    useEffect(() => {
        if (selectedMetal !== 'platinum') {
            setMetalCarat(MetalDetails[selectedMetal])
        }
    }, [selectedMetal])

    useEffect(() => {
        const CaratData = MetalDetails[selectedMetal].find((carat: CaratType) => (carat.id === selectedCarat || carat.id === selectedMetal));
        if (selectedMetal !== 'platinum') {
            CaratData && setSelectedUrl(CaratData.url);
        } else {
            CaratData && setSelectedUrl(CaratData.url);
        }
    }, [selectedCarat, selectedMetal])

    const handleSelectMetal = (metal: string) => {
        setSelectedMetal(metal);
    }

    const handleSelectCarat = (carat: string) => {
        setSelectedCarat(carat);
    }

    return {
        swatchColor,
        MetalDetails,
        selectedMetal,
        selectedCarat,
        metalCarat,
        selectedUrl,
        handleSelectMetal,
        handleSelectCarat
    }
}

export default UseMetalColorTooltip;