import React, { ReactHTML, useEffect, useRef, useState } from "react";
import classes from "./TabContent.module.css";
import Icon from "../../Icons";
import { useMediaQuery } from "react-responsive";

const TabContent = ({ content }: { content: JSX.Element }) => {
  const [showButton, setShowButton] = useState(false);
  const mobileScrollRoot = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const handleScroll = () => {
    const div = mobileScrollRoot.current;
    const threshold = 300;
    if (div) {
      const scrollY = div.scrollTop;
      setShowButton(scrollY > threshold);
    }
  };

  useEffect(() => {
    const div = mobileScrollRoot.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (div) {
        div.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);
  const handleScrollTopClick = () => {
    if (mobileScrollRoot.current) {
      if (mobileScrollRoot.current) {
        const duration = 500; // Adjust duration as needed
        const startTime = performance.now();
        const startScrollTop = mobileScrollRoot.current.scrollTop;
        const easeInOut = (t: number) =>
          t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

        const animateScroll = (currentTime: number) => {
          const elapsedTime = currentTime - startTime;
          const fraction = Math.min(1, elapsedTime / duration);
          const scrollToTop =
            startScrollTop - startScrollTop * easeInOut(fraction);

          if (mobileScrollRoot.current) {
            mobileScrollRoot.current.scrollTop = scrollToTop;
          }

          if (elapsedTime < duration) {
            requestAnimationFrame(animateScroll);
          }
        };

        requestAnimationFrame(animateScroll);
      }
    }
  };
  return (
    <>
      <div className={classes.content} ref={mobileScrollRoot}>
        {" "}
        {content}
      </div>
      {isMobile ? (
        <button
          onClick={handleScrollTopClick}
          className={`stickyButton ${showButton ? "active" : ""} ${
            classes.topToBottomBtn
          }`}
        >
          <span className={"iconTop"}>
            <Icon name={"hk-arrow-up-forward"} />
          </span>
        </button>
      ) : null}
    </>
  );
};

export default TabContent;
