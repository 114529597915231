import React,{ RefObject, useMemo } from "react";
import { showExpandColumn } from "../column.utils";
import { MRT_TableBodyRowGrabHandle } from "../components/body";
import { MRT_ExpandAllButton } from "../components/buttons/MRT_ExpandAllButton";
import { MRT_ExpandButton } from "../components/buttons/MRT_ExpandButton";
import { MRT_ToggleRowActionMenuButton } from "../components/buttons/MRT_ToggleRowActionMenuButton";
import {
  MRT_Cell,
  MRT_ColumnOrderState,
  MRT_DefinedTableOptions,
  MRT_GroupingState,
  MRT_Row,
  MRT_RowData,
  MRT_TableInstance,
} from "../types";

interface Params<TData extends MRT_RowData> {
  columnOrder: MRT_ColumnOrderState;
  creatingRow: MRT_Row<TData> | null;
  grouping: MRT_GroupingState;
  tableOptions: MRT_DefinedTableOptions<TData>;
}

export const useMRT_DisplayColumns = <TData extends MRT_RowData>({
  columnOrder,
  creatingRow,
  grouping,
  tableOptions
}: Params<TData>) => {
    const initialState = tableOptions && tableOptions['initialState'];
  return useMemo(
    () =>
      [
        (tableOptions.state?.columnOrder ?? columnOrder).includes(
          "mrt-row-drag"
        ) && {
          Cell: <TData extends MRT_RowData>({
            row,
            rowRef,
            table,
          }: {
            row: MRT_Row<TData>;
            rowRef: RefObject<HTMLTableRowElement>;
            table: MRT_TableInstance<TData>;
          }) => (
            <MRT_TableBodyRowGrabHandle
              row={row}
              rowRef={rowRef}
              table={table}
            />
          ),
          header: tableOptions.localization.move,
          size: 45,
          ...tableOptions.defaultDisplayColumn,
          ...tableOptions.displayColumnDefOptions?.["mrt-row-drag"],
          id: "mrt-row-drag",
        },
        ((tableOptions.state?.columnOrder ?? columnOrder).includes(
          "mrt-row-actions"
        ) ||
          (creatingRow && tableOptions.createDisplayMode === "row")) && {
          Cell: <TData extends MRT_RowData>({
            cell,
            row,
            table,
          }: {
            cell: MRT_Cell<TData>;
            row: MRT_Row<TData>;
            table: MRT_TableInstance<TData>;
          }) => (
            <MRT_ToggleRowActionMenuButton
              cell={cell}
              row={row}
              table={table}
            />
          ),
          header: tableOptions.localization.actions,
          size: 70,
          ...tableOptions.defaultDisplayColumn,
          ...tableOptions.displayColumnDefOptions?.["mrt-row-actions"],
          id: "mrt-row-actions",
        },
        (tableOptions.state?.columnOrder ?? columnOrder).includes(
          "mrt-row-expand"
        ) &&
          showExpandColumn(
            tableOptions,
            tableOptions.state?.grouping ?? grouping
          ) && (initialState && !initialState['isMobileView']) && {
            Cell: <TData extends MRT_RowData>({
              row,
              table,
            }: {
              row: MRT_Row<TData>;
              table: MRT_TableInstance<TData>;
            }) => <MRT_ExpandButton row={row} table={table} />,
            Header: tableOptions.enableExpandAll
              ? <TData extends MRT_RowData>({
                  table,
                }: {
                  table: MRT_TableInstance<TData>;
                }) => <MRT_ExpandAllButton table={table} />
              : null,
            header: '',
            isSticky: true,
            size: 45,
            ...tableOptions.defaultDisplayColumn,
            ...tableOptions.displayColumnDefOptions?.["mrt-row-expand"],
            id: "mrt-row-expand",
          },
        (tableOptions.state?.columnOrder ?? columnOrder).includes(
          "mrt-row-select"
        ) &&
          (tableOptions.state?.columnOrder ?? columnOrder).includes(
            "mrt-row-numbers"
          ) && {
            Cell: <TData extends MRT_RowData>({
              row,
            }: {
              row: MRT_Row<TData>;
            }) => row.index + 1,
            Header: () => tableOptions.localization.rowNumber,
            header: tableOptions.localization.rowNumbers,
            size: 45,
            ...tableOptions.defaultDisplayColumn,
            ...tableOptions.displayColumnDefOptions?.["mrt-row-numbers"],
            id: "mrt-row-numbers",
          },
      ].filter(Boolean),
    [
      columnOrder,
      grouping,
      tableOptions.displayColumnDefOptions,
      tableOptions.editDisplayMode,
      tableOptions.enableColumnDragging,
      tableOptions.enableColumnFilterModes,
      tableOptions.enableColumnOrdering,
      tableOptions.enableEditing,
      tableOptions.enableExpandAll,
      tableOptions.enableExpanding,
      tableOptions.enableGrouping,
      tableOptions.enableRowActions,
      tableOptions.enableRowDragging,
      tableOptions.enableRowNumbers,
      tableOptions.enableRowOrdering,
      tableOptions.enableRowSelection,
      tableOptions.enableSelectAll,
      tableOptions.localization,
      tableOptions.positionActionsColumn,
      tableOptions.renderDetailPanel,
      tableOptions.renderRowActionMenuItems,
      tableOptions.renderRowActions,
      tableOptions.state?.columnOrder,
      tableOptions.state?.grouping,
    ]
  );
};
