import * as React from "react";
import {useEffect, useRef, useState} from "react";
import { MRT_ColumnActionMenu } from "../menus/MRT_ColumnActionMenu";
import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";

interface Props<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

function classNames(...classes:string[]) {
  return classes.filter(Boolean).join(" ");
}

function listenForOutsideClicks(
    listening: boolean,
    setListening: (listening: boolean) => void,
    menuRef: React.RefObject<HTMLElement>,
    setIsOpen: (isOpen: boolean) => void,
): () => void {
  return () => {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    ["click", "touchstart"].forEach((type) => {
      document.addEventListener(type, (evt) => {
        const cur = menuRef.current;
        const node = evt.target as Node;
        if ( cur && cur.contains(node)) return;
        setIsOpen(false);
      });
    });
  };
}
export const MRT_TableHeadCellColumnActionsButton = <
  TData extends MRT_RowData
>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  const {
    options: {
      icons: { MoreVertIcon },
      localization,
      muiColumnActionsButtonProps,
    },
  } = table;
  const { column } = header;
  const { columnDef } = column;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const mTableHeadCellColumnActionsButtonProps =
    muiColumnActionsButtonProps instanceof Function
      ? muiColumnActionsButtonProps({ column, table })
      : muiColumnActionsButtonProps;

  const mcTableHeadCellColumnActionsButtonProps =
    columnDef.muiColumnActionsButtonProps instanceof Function
      ? columnDef.muiColumnActionsButtonProps({
          column,
          table,
        })
      : columnDef.muiColumnActionsButtonProps;

  const iconButtonProps = {
    ...mTableHeadCellColumnActionsButtonProps,
    ...mcTableHeadCellColumnActionsButtonProps,
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [positionRight, setPositionRight] = useState<boolean>(false);

  const toggleDropdown = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen(!isOpen);
    const rect = await dropdownRef.current?.getBoundingClientRect();
    event.stopPropagation();
    event.preventDefault();
    setPositionRight(rect?.left! < 220);
  };

  const [listening, setListening] = useState<boolean>(false);
  useEffect(
    listenForOutsideClicks(listening, setListening, dropdownRef, setIsOpen)
  );

  return (
      <>
        <div className="headless_showhide-column" ref={dropdownRef}>
          <div className="headless_tooltip_bottom"
               data-tip={iconButtonProps?.title ?? localization.columnActions}
          >
            <button
                aria-label={localization.expand}
                onClick={toggleDropdown}
                className={`headless_expandBtn-tooltipBtn`}
            >
              <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          {isOpen && <div
              className={classNames(`headless_cellAction-btn`,
                  positionRight ? `headless_left-0`: `headless_right-0`)}
              role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
            <div className="headless_columnMenu-div" role="none">
              <MRT_ColumnActionMenu
                  anchorEl={anchorEl}
                  header={header}
                  setAnchorEl={setAnchorEl}
                  table={table}
                  setIsOpen={setIsOpen}
              />
            </div>
          </div>}
        </div>
      </>
  );
};
