import React, { useMemo } from 'react';

export const DOTS: string = '...';

const range = (start: number, end: number): number[] => {
  let length: number = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface PaginationProps {
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
}

export const usePagination = ({
                                totalCount,
                                pageSize,
                                siblingCount = 1,
                                currentPage
                              }: PaginationProps): number[] | undefined => {
  const paginationRange: any = useMemo(() => {
    const totalPageCount: number = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers: number = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex: number = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex: number = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );
    /*
      We do not want to show dots if there is only one position left
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots: boolean = leftSiblingIndex >= 2;
    const shouldShowRightDots: boolean = rightSiblingIndex <= totalPageCount - 2;
    const firstPageIndex: number = 1;
    const lastPageIndex: number = totalPageCount;
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount: number = 3 + 2 * siblingCount;
      let leftRange: number[] = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount: number = 3 + 2 * siblingCount;
      let rightRange: number[] = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange: number[] = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
