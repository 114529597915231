import React from 'react';
import CMSPage from './CMSPage';
import { PropsFromScreenManager } from '../../types';
import Screen from '../../Screen';

class CMSScreen extends Screen {
  constructor(props: PropsFromScreenManager) {
    super(props);
  }

  render() {
    return <CMSPage instanceData={this.props.instanceData} />;
  }
}

export default CMSScreen;
