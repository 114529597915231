export default {
    HK_AR: "hk-AR",
    HK_IMAGE: "hk-image",
    HK_HELP: "hk_help",
    HK_EXIT: "hk-exit",
    HK_SQUARE_PENCIL: "hk-square-pencil",
    HK_CHEVRON_DOWN_M: "hk-chevron-down_M",
    HK_CART: "HK_CART",
    HK_ENVELOPE: "hk-envelope",
    HK_HEART: "hk-heart",
    HK_FILTER: "hk-Filter",
    HK_CHEVRON_UP: "hk_chevron_up",
    HK_CHEVRON_DOWN: "hk_chevron_down",
    HK_VIEW_DIAMOND: "hk-viewDiamond",
    HK_CHEVRON_LEFT: "hk_chevron_left",
    HK_ARROW_UP_FORWARD: "hk-arrow-up-forward",
    HK_SPARKLES: "hk-sparkles",
    HK_CHEVRON_RIGHT: "hk_chevron_right",
    HK_XMARK: "hk_xmark",
    HK_CHEVRON_LEFT_M:"hk-chevron-left_M",
    HK_MENU:"hk_menu"
}