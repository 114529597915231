import React, { FC } from "react";
import { DiamondDetailProps } from "../../../types";
import classes from "./DiamondDetail.module.css";

function capitalizeUppercaseWords(str: any) {
  return str.replace(/\b[A-Z]+\b/g, function (match: string) {
    return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
  });
}
const DiamondDetail: FC<DiamondDetailProps> = (props) => {
  const { title, price, carat, cut, color, clarity } = props;
  return (
    <>
      <div className={`${classes.root} hk_detailsContainer`}>
        <h2 className={"hk_detailHeading"}>
          {title ? <span>{capitalizeUppercaseWords(title)}</span> : null}
          {price ? <span className="font-primary">${price}</span> : "-"}
        </h2>
        <div className={"hk_details_wrap"}>
          <div className={"hk_detail"}>
            <span>Carat</span>
            {carat ? <span>{carat}</span> : null}
          </div>
          <div className={"hk_detail"}>
            <span>Color</span>
            <span>{color}</span>
          </div>
          <div className={"hk_detail"}>
            <span>Cut</span>
            <span>{cut}</span>
          </div>
          <div className={"hk_detail"}>
            <span>Clarity</span>
            <span>{clarity}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default DiamondDetail;
