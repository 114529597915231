import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  createRow,
  getAllLeafColumnDefs,
  getColumnId,
  getDefaultColumnFilterFn,
  getDefaultColumnOrderIds,
  prepareColumns,
} from "../column.utils";
import {
  MRT_Cell,
  MRT_Column,
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnOrderState,
  MRT_DefinedTableOptions,
  MRT_DensityState,
  MRT_FilterOption,
  MRT_GroupingState,
  MRT_Row,
  MRT_RowData,
  MRT_TableInstance,
  MRT_TableState,
  MRT_Updater,
  MRT_PaginationArrayState,
  reduxPaginationType,
  expandRowType
} from "../types";
import { useMRT_DisplayColumns } from "./useMRT_DisplayColumns";
import { useMRT_Effects } from "./useMRT_Effects";
import { PropsFromScreenManager } from "../../../../../types";

export const useMRT_TableInstance: <TData extends MRT_RowData>(
  tableOptions: MRT_DefinedTableOptions<TData>
) => MRT_TableInstance<TData> = <TData extends MRT_RowData>(
  tableOptions: MRT_DefinedTableOptions<TData>
) => {
  const bottomToolbarRef = useRef<HTMLDivElement>(null);
  const editInputRefs = useRef<Record<string, HTMLInputElement>>({});
  const filterInputRefs = useRef<Record<string, HTMLInputElement>>({});
  const searchInputRef = useRef<HTMLInputElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadCellRefs = useRef<Record<string, HTMLTableCellElement>>({});
  const tablePaperRef = useRef<HTMLDivElement>(null);
  const topToolbarRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const tableFooterRef = useRef<HTMLTableSectionElement>(null);

  const initialState: Partial<MRT_TableState<TData>> = useMemo(() => {
    const initState = tableOptions.initialState ?? {};
    initState.columnOrder =
      initState.columnOrder ?? getDefaultColumnOrderIds(tableOptions);
    initState.globalFilterFn = tableOptions.globalFilterFn ?? "fuzzy";
    return initState;
  }, [tableOptions]);

  const [creatingRow, _setCreatingRow] = useState<MRT_Row<TData> | null>(
    initialState.creatingRow ?? null
  );
  const [columnFilterFns, setColumnFilterFns] =
    useState<MRT_ColumnFilterFnsState>(() =>
      Object.assign(
        {},
        ...getAllLeafColumnDefs(
          tableOptions.columns as MRT_ColumnDef<TData>[]
        ).map((col) => ({
          [getColumnId(col)]:
            col.filterFn instanceof Function
              ? col.filterFn.name ?? "custom"
              : col.filterFn ??
                initialState?.columnFilterFns?.[getColumnId(col)] ??
                getDefaultColumnFilterFn(col),
        }))
      )
    );
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    initialState.columnOrder ?? []
  );
  const [paginationArray, setPaginationArray] =
    useState<MRT_PaginationArrayState>(initialState.paginationArray ?? []);

  const [density, setDensity] = useState<MRT_DensityState>(
    initialState?.density ?? "comfortable"
  );
  const [draggingColumn, setDraggingColumn] =
    useState<MRT_Column<TData> | null>(initialState.draggingColumn ?? null);
  const [draggingRow, setDraggingRow] = useState<MRT_Row<TData> | null>(
    initialState.draggingRow ?? null
  );
  const [editingCell, setEditingCell] = useState<MRT_Cell<TData> | null>(
    initialState.editingCell ?? null
  );
  const [editingRow, setEditingRow] = useState<MRT_Row<TData> | null>(
    initialState.editingRow ?? null
  );
  const [globalFilterFn, setGlobalFilterFn] = useState<MRT_FilterOption>(
    initialState.globalFilterFn ?? "fuzzy"
  );
  const [grouping, setGrouping] = useState<MRT_GroupingState>(
    initialState.grouping ?? []
  );
  const [hoveredColumn, setHoveredColumn] = useState<Partial<
    MRT_Column<TData>
  > | null>(initialState.hoveredColumn ?? null);
  const [hoveredRow, setHoveredRow] = useState<Partial<MRT_Row<TData>> | null>(
    initialState.hoveredRow ?? null
  );
  const [isFullScreen, setIsFullScreen] = useState<boolean>(
    initialState?.isFullScreen ?? false
  );
  const [showAlertBanner, setShowAlertBanner] = useState<boolean>(
    tableOptions.initialState?.showAlertBanner ?? false
  );
  const [showColumnFilters, setShowColumnFilters] = useState<boolean>(
    initialState?.showColumnFilters ?? false
  );
  const [showGlobalFilter, setShowGlobalFilter] = useState<boolean>(
    initialState?.showGlobalFilter ?? false
  );
  const [showToolbarDropZone, setShowToolbarDropZone] = useState<boolean>(
    initialState?.showToolbarDropZone ?? false
  );
  const [isMobileView, setIsMobileView] = useState<boolean>(
      initialState?.isMobileView ?? false
  );

  const [isLoading, setIsLoading] = useState<boolean>(
      initialState?.isLoading ?? false
  );

  const [instanceData,setInstanceData] = useState<PropsFromScreenManager['instanceData'] | boolean>(
    initialState?.instanceData ?? false
  )

  const [compareDataIds,setCompareDataIds] = useState<string[]>(
    initialState?.compareDataIds ?? []
  )

  const [scrollToTop,setScrollToTop] = useState<boolean>(
      initialState?.scrollToTop ?? false
  )
  const[reduxPagination,setReduxPagination] = useState<reduxPaginationType>(initialState?.reduxPagination ?? {});
  const [expandRow,setIsExpandRow] = useState<expandRowType>(initialState?.expandRow ?? {})
  const [scrollTop,setScrollTop] = useState<boolean>(
      initialState?.scrollTop ?? false
  )

  useEffect(()=>{
    if(initialState?.scrollTop){
      setScrollTop(initialState?.scrollTop);
    }
  },[initialState?.scrollTop])

  useEffect(()=>{
    if(initialState?.paginationArray){
      setPaginationArray(initialState?.paginationArray)
    }
  },[initialState?.paginationArray])

  useEffect(()=>{
    if(initialState?.expandRow){
      setIsExpandRow(initialState?.expandRow)
    }
  },[initialState?.expandRow])

  useEffect(()=>{
    if(initialState?.reduxPagination){
      setReduxPagination(initialState?.reduxPagination)
    }
  },[initialState?.reduxPagination])

  useEffect(()=>{
    if(initialState.isLoading !== undefined){
      setIsLoading(initialState.isLoading);
    }
  },[initialState.isLoading])

  useEffect(()=>{
    if(initialState.isMobileView !== undefined){
      setIsMobileView(initialState.isMobileView)
    }
  },[initialState.isMobileView])

  useEffect(()=>{
    if(initialState['columnOrder']){
      setColumnOrder(initialState['columnOrder']);
    }
  },[initialState['columnOrder']])

  useEffect(()=>{
    if(initialState.instanceData !== undefined){
      setInstanceData(initialState.instanceData);
    }
  },[initialState.instanceData])
  useEffect(()=>{
    if(initialState?.compareDataIds !== undefined){
      setCompareDataIds(initialState?.compareDataIds);
    }
  },[initialState?.compareDataIds])

  useEffect(()=>{
    if(initialState?.scrollToTop !== undefined){
      setScrollToTop(initialState?.scrollToTop);
    }
  },[initialState?.scrollToTop])

  const displayColumns = useMRT_DisplayColumns({
    columnOrder,
    creatingRow,
    grouping,
    tableOptions,
  });

  const columnDefs = useMemo(
    () =>
      prepareColumns({
        aggregationFns: tableOptions.aggregationFns as any,
        columnDefs: [...displayColumns, ...tableOptions.columns] as any,
        columnFilterFns: tableOptions.state?.columnFilterFns ?? columnFilterFns,
        defaultDisplayColumn: tableOptions.defaultDisplayColumn ?? {},
        filterFns: tableOptions.filterFns as any,
        sortingFns: tableOptions.sortingFns as any,
      }).filter(column => tableOptions.enableGrouping ? column : column.accessorKey !== "type"),
    [
      columnFilterFns,
      displayColumns,
      tableOptions.columns,
      tableOptions.state?.columnFilterFns,
    ]
  );

  const data: TData[] = useMemo(
    () =>
      (tableOptions.state?.isLoading || tableOptions.state?.showSkeletons) &&
      !tableOptions.data.length
        ? [
            ...Array(
              tableOptions.state?.pagination?.pageSize ||
                initialState?.pagination?.pageSize ||
                10
            ).fill(null),
          ].map(() =>
            Object.assign(
              {},
              ...getAllLeafColumnDefs(tableOptions.columns).map((col) => ({
                [getColumnId(col)]: null,
              }))
            )
          )
        : tableOptions.data,
    [
      tableOptions.data,
      tableOptions.state?.isLoading,
      tableOptions.state?.showSkeletons,
    ]
  );


  function useSkipper() {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
  }
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  //@ts-ignore
  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel:
      tableOptions.enableExpanding || tableOptions.enableGrouping
        ? getExpandedRowModel()
        : undefined,
    getFacetedMinMaxValues: tableOptions.enableFacetedValues
      ? getFacetedMinMaxValues()
      : undefined,
    getFacetedRowModel: tableOptions.enableFacetedValues
      ? getFacetedRowModel()
      : undefined,
    getFacetedUniqueValues: tableOptions.enableFacetedValues
      ? getFacetedUniqueValues()
      : undefined,
    getFilteredRowModel:
      tableOptions.enableColumnFilters ||
      tableOptions.enableGlobalFilter ||
      tableOptions.enableFilters
        ? getFilteredRowModel()
        : undefined,
    getGroupedRowModel: tableOptions.enableGrouping
      ? getGroupedRowModel()
      : undefined,
    getPaginationRowModel: tableOptions.enablePagination
      ? getPaginationRowModel()
      : undefined,
    getSortedRowModel: tableOptions.enableSorting
      ? getSortedRowModel()
      : undefined,
    getSubRows: (row) => row?.subRows,
    onColumnOrderChange: setColumnOrder,
    onGroupingChange: setGrouping,
    ...tableOptions,
    //@ts-ignore
    columns: columnDefs,
    data,
    autoResetPageIndex,
    //@ts-ignore
    // defaultColumn: __defaultColumn,
    globalFilterFn: tableOptions.filterFns?.[globalFilterFn ?? "fuzzy"],
    initialState,
    state: {
      columnFilterFns,
      columnOrder,
      creatingRow,
      density,
      draggingColumn,
      draggingRow,
      editingCell,
      editingRow,
      globalFilterFn,
      grouping,
      hoveredColumn,
      hoveredRow,
      isFullScreen,
      showAlertBanner,
      showColumnFilters,
      showGlobalFilter,
      showToolbarDropZone,
      paginationArray,
      isMobileView,
      isLoading,
      expandRow,
      reduxPagination,
      instanceData,
      compareDataIds,
      scrollToTop,
      scrollTop,
      setScrollTop,
      ...tableOptions.state,
    },
  }) as MRT_TableInstance<TData>;

  // @ts-ignore
  table.refs = {
    // @ts-ignore
    bottomToolbarRef,
    editInputRefs,
    filterInputRefs,
    // @ts-ignore
    searchInputRef,
    // @ts-ignore
    tableContainerRef,
    // @ts-ignore
    tableFooterRef,
    tableHeadCellRefs,
    // @ts-ignore
    tableHeadRef,
    // @ts-ignore
    tablePaperRef,
    // @ts-ignore
    topToolbarRef,
  };

  const setCreatingRow = (row: MRT_Updater<MRT_Row<TData> | null | true>) => {
    let _row = row;
    if (row === true) {
      _row = createRow(table);
    }
    tableOptions?.onCreatingRowChange?.(_row as MRT_Row<TData> | null) ??
      _setCreatingRow(_row as MRT_Row<TData> | null);
  };

  table.setCreatingRow = setCreatingRow;
  table.setColumnFilterFns =
    tableOptions.onColumnFilterFnsChange ?? setColumnFilterFns;
  table.setDensity = tableOptions.onDensityChange ?? setDensity;
  table.setDraggingColumn =
    tableOptions.onDraggingColumnChange ?? setDraggingColumn;
  table.setDraggingRow = tableOptions.onDraggingRowChange ?? setDraggingRow;
  table.setEditingCell = tableOptions.onEditingCellChange ?? setEditingCell;
  table.setEditingRow = tableOptions.onEditingRowChange ?? setEditingRow;
  table.setGlobalFilterFn =
    tableOptions.onGlobalFilterFnChange ?? setGlobalFilterFn;
  table.setHoveredColumn =
    tableOptions.onHoveredColumnChange ?? setHoveredColumn;
  table.setHoveredRow = tableOptions.onHoveredRowChange ?? setHoveredRow;
  table.setIsFullScreen = tableOptions.onIsFullScreenChange ?? setIsFullScreen;
  table.setShowAlertBanner =
    tableOptions.onShowAlertBannerChange ?? setShowAlertBanner;
  table.setShowColumnFilters =
    tableOptions.onShowColumnFiltersChange ?? setShowColumnFilters;
  table.setShowGlobalFilter =
    tableOptions.onShowGlobalFilterChange ?? setShowGlobalFilter;
  table.setShowToolbarDropZone =
    tableOptions.onShowToolbarDropZoneChange ?? setShowToolbarDropZone;

  useMRT_Effects(table);

  return table;
};
