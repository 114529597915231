import {initialTooltipStateType, TooltipAction, TooltipDatatype} from "../types";
import TooltipConstant from "../constant/TooltipConstant";

const initialTooltipState :initialTooltipStateType  = {
    tooltipData :[],
    isLoading: false,
    error: ''
}

const TooltipReducer = (state: initialTooltipStateType = initialTooltipState, action: TooltipAction): initialTooltipStateType => {
    switch (action.type) {
        case TooltipConstant.SET_TOOLTIP_DATA_LOADING:
            return {
                ...state,
                isLoading:true
            }
        case TooltipConstant.SET_TOOLTIP_DATA_SUCCESS:
            return {
                ...state,
                tooltipData:action.payload as TooltipDatatype[],
                isLoading:false,
                error:''
            }
        case TooltipConstant.SET_TOOLTIP_DATA_ERROR:
            return {
                ...state,
                isLoading: false,
                tooltipData: [],
                error: action.payload as (Error | string)
            }
        case TooltipConstant.SET_INITIAL_TOOLTIP:
            return initialTooltipState;
        default:
            return state;
    }
};

export default TooltipReducer;
