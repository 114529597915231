import {DiamondAction, DiamondState, TableData} from "../types";
import DiamondConstant from "../constant/DiamondConstant";

const initialDiamondState: DiamondState = {
   details : {},
   isLoading: false,
   error: '',
   isDiamondAvailable :true,
   isBackDiamondPage: false
};
const diamondReducer = (state = initialDiamondState, action: DiamondAction) :DiamondState => {
    switch (action.type) {
        case DiamondConstant.SELECT_DIAMOND_DETAILS_LOADING:
            return {
                ...state,
                isLoading : true
            };
        case DiamondConstant.SELECT_DIAMOND_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading : false,
                error: "",
                details : action.payload as TableData
            };
        case DiamondConstant.SELECT_DIAMOND_DETAILS_FAIL:
            return {
                ...state,
                isLoading : false,
                details: {},
                error: action.payload as (Error | string)
            };
        case DiamondConstant.SET_DIAMOND_AVAILABLE:
            return {
                ...state,
                isDiamondAvailable: action.payload as boolean
            };
        case DiamondConstant.IS_BACK_TO_SELECT_DIAMOND_PAGE:
            return {
                ...state,
                isBackDiamondPage : action.payload as boolean
            };
        case DiamondConstant.SET_INITIAL_DIAMOND_OPTIONS:
            return initialDiamondState;
        default:
            return state;
    }
}
export default diamondReducer;
