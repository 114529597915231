import { useCallback, useEffect } from 'react';
import { PropsFromScreenManager, UseCMSPageProps } from '../../types';
import constants from '../../constant/general'

const UseStorePage = (
  {
    setInitialOption,
    setHeaderTabDefault,
    setHeaderTabToSetting,
    setInitialDiamondOption,
    setInitialFilter,
    setTableInitialState,
    setInitialFooter,
    setInitialHeader,
    setInitialStartWithSettingData,
    SetInitialTooltip,
    instanceData: { screenManager },
    setInitialCart
  }: (UseCMSPageProps & PropsFromScreenManager)) => {

  const resetAllStates = () => {
    // Combine all reset functions into one
    setInitialOption();
    setInitialDiamondOption();
    setInitialFilter();
    setTableInitialState();
    setInitialFooter();
    setInitialStartWithSettingData();
    SetInitialTooltip();
    setInitialCart();
  };

  const setHeaderTab = (view: string) => {
    if (view?.toLowerCase() === constants.DIAMOND?.toLowerCase()) {
      setHeaderTabDefault();
    } else {
      setHeaderTabToSetting(view);
    }
  };

  const handleResetState = useCallback((view: string, styleId?: string | null) => {
    const builderMode = localStorage.getItem(constants.BUILDER_MODE_KEY);
    if (builderMode?.toLowerCase() !== constants.EDIT?.toLowerCase()) {
      setHeaderTab(view);
      resetAllStates();
    }
    const sessionStorageObject = {
      rpid: constants.DEFAULT_RPID,
      [constants.BUILDER_MODE_KEY]: constants.BUILD
    };
    Object.entries(sessionStorageObject).forEach(([key, value]) => {
      localStorage.setItem(key, JSON.stringify(value));
    });

    if (screenManager && (screenManager as any).changeScreen) {
      (screenManager as any).changeScreen({
        viewName: view, styleId: styleId || null
      });
    }
  }, [setInitialOption, setInitialDiamondOption, setInitialFilter, setTableInitialState, setHeaderTabToSetting, setHeaderTabDefault, setInitialHeader, setInitialFooter, setInitialStartWithSettingData, SetInitialTooltip]);

  const ContentArray = [{
    link: constants.DYO?.toLowerCase(), styleId: constants.DYO_STYLE_ID?.toUpperCase(), onClick: handleResetState, heading: 'Design Your Own'
  }, {
    link: constants.DIAMOND?.toLowerCase(), styleId: null, onClick: handleResetState, heading: 'Start With a Diamond'
  }, {
    link: constants.SETTING?.toLowerCase(), styleId: null, onClick: handleResetState, heading: 'Start With a Setting'
  }];

  useEffect(() => {
    if (screenManager) {
      (screenManager as any).props.setStateManagerData('currentScreen', 'Store Screen');
      (screenManager as any).props.setStateManagerData('currentView', 'Main View');
    }
  }, []);
  return {
    ContentArray
  };
};

export default UseStorePage;
