import React from "react";
import { MRT_TableHeadCell } from "./MRT_TableHeadCell";
import {
  MRT_HeaderGroup,
  MRT_RowData,
  MRT_TableInstance,
  MRT_Header,
} from "../../types";
import { VirtualItem } from "@tanstack/react-virtual";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props<TData extends MRT_RowData> {
  headerGroup: MRT_HeaderGroup<TData>;
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}
export const MRT_TableHeadRow = <TData extends MRT_RowData>({
  headerGroup,
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...rest
}: Props<TData>) => {
  const {
    options: { layoutMode, muiTableHeadRowProps },
  } = table;

  const tableRowProps =
    muiTableHeadRowProps instanceof Function
      ? muiTableHeadRowProps({ headerGroup, table })
      : muiTableHeadRowProps;

  return (
    <tr
      className={classNames(
        `headless_thead-row`,
        layoutMode === "grid" ? "headles_flex" : "headless_table-row"
      )}
      {...tableRowProps}
    >
      {virtualPaddingLeft ? (
        <th style={{ display: "flex", width: virtualPaddingLeft }} />
      ) : null}
      {(virtualColumns ?? headerGroup.headers).map((headerOrVirtualHeader) => {
        const header = virtualColumns
          ? headerGroup.headers[headerOrVirtualHeader.index]
          : (headerOrVirtualHeader as MRT_Header<TData>);

        return header ? (
          <MRT_TableHeadCell header={header} key={header.id} table={table} />
        ) : null;
      })}
      {virtualPaddingRight ? (
        <th style={{ display: "flex", width: virtualPaddingRight }} />
      ) : null}
    </tr>
  );
};
