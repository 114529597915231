import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { PropsFromScreenManager, UseHeaderTabType } from "../../../types";
import { HKCustomize, HKRing } from "../../Images";

const UseHeaderTab = (props: UseHeaderTabType & PropsFromScreenManager) => {
  const {
    diamond,
    ring,
    footer,
    setHeaderTabToSetting,
    setInitialOption,
    setDiamondTabIconTrue,
    setHeaderTabDefault,
    setSettingTabIconTrue,
    setIsShowFooter,
    instanceData: { screenManager },
    setInitialStartWithSettingData,
    setInitialDiamondOption,
    setBothTabIconTrue,
    openTab,
    setInitialFooter,
    setInitialFilter,
    setTableInitialState,
    setStyleID,
    resetPath,
    changeBackClick,
    setIsInitialOption,
  } = props;
  const navigate = useNavigate();

  const setCenterTabData = useCallback(
    (URLPath: string[]) => {
      switch (true) {
        case URLPath.includes("#diamond"):
          if (!(Object.keys(ring.options).length > 0)) {
            setHeaderTabDefault();
          } else {
            setSettingTabIconTrue();
          }
          break;
        case URLPath.includes("#setting"):
          if (URLPath.includes("diamondId")) {
            setDiamondTabIconTrue("setting");
          } else {
            setHeaderTabToSetting("setting");
          }
          break;
        case URLPath.includes("#dyo"):
          if (
            URLPath.includes("diamondId") &&
            window.location.hash.includes("RP")
          ) {
            setDiamondTabIconTrue("setting");
          } else if (
            URLPath.includes("diamondId") &&
            window.location.hash.includes("RB")
          ) {
            setDiamondTabIconTrue("dyo");
          } else if (window.location.hash.includes("RP")) {
            setHeaderTabToSetting("setting");
          } else {
            setHeaderTabToSetting("dyo");
          }
          break;
        default:
          break;
      }
    },
    [
      setHeaderTabDefault,
      setDiamondTabIconTrue,
      setHeaderTabToSetting,
      footer.styleID,
    ]
  );

  useEffect(() => {
    const updatePathName: string[] = window.location.hash.split(/['/=']/);
    if (updatePathName) {
      setCenterTabData(updatePathName);
    }
    return () => {};
  }, [window.location.hash]);

  useEffect(() => {
    if (footer.toggle) {
      if (window.location.hash.split(/['/=']/).includes("#diamond")) {
        setBothTabIconTrue(["SELECT SETTING", "SELECT DIAMOND"]);
      } else {
        setBothTabIconTrue(["SELECT DIAMOND", "SELECT SETTING"]);
      }
    }
  }, [footer.toggle]);
  useEffect(() => {
    const { isInitial, currentPath } = ring.isInitialOption;
    if (isInitial) {
      const currentPathName: string[] = window.location.hash.split(/['/=']/);
      if (
        currentPath.includes("#setting") &&
        currentPath.includes("diamondId")
      ) {
        setDiamondTabIconTrue("dyo");
        resetPath("");
        (screenManager as any).changeScreen({
          viewName: "dyo",
          id: diamond.details.id,
          styleId: "RB001",
        });
      } else if (
        currentPath.includes("#dyo") &&
        currentPath.includes("diamondId")
      ) {
        resetPath("dyo");
        setDiamondTabIconTrue("dyo");
        (screenManager as any).changeScreen({
          viewName: "dyo",
          id: diamond.details.id,
          styleId: "RB001",
        });
      } else if (currentPath.includes("#dyo")) {
        resetPath("dyo");
        setHeaderTabToSetting("dyo");
        (screenManager as any).changeScreen({
          viewName: "dyo",
          id: null,
          styleId: "RB001",
        });
      } else {
        resetPath("");
        setHeaderTabToSetting("dyo");
        setInitialDiamondOption();
        setInitialFilter();
        setTableInitialState();
        setIsShowFooter(false);
        (screenManager as any).changeScreen({
          viewName: "dyo",
          id: null,
          styleId: "RB001",
        });
      }
      setIsInitialOption({ isInitial: false, currentPath: currentPathName });
    }
  }, [ring.isInitialOption]);

  const handleChangePath = useCallback(
    async (key: string) => {
      const currentPathName: string[] = window.location.hash.split(/['/=']/);
      changeBackClick(false);
      setInitialOption();
      setInitialStartWithSettingData();
      if (key === "setting") {
        resetPath("");
        if (
          currentPathName.includes("#dyo") &&
          currentPathName.includes("diamondId")
        ) {
          setDiamondTabIconTrue("setting");
          (screenManager as any).changeScreen({
            viewName: "setting",
            id: diamond.details.id,
            styleId: "",
          });
        } else {
          resetPath("");
          setHeaderTabToSetting("setting");
          setInitialDiamondOption();
          setInitialFilter();
          setTableInitialState();
          setIsShowFooter(false);
          (screenManager as any).changeScreen({
            viewName: "setting",
            id: null,
            styleId: "",
          });
        }
      } else {
        setIsInitialOption({ isInitial: true, currentPath: currentPathName });
      }
    },
    [
      setIsInitialOption,
      setInitialFilter,
      setIsShowFooter,
      setTableInitialState,
      setInitialOption,
      setInitialStartWithSettingData,
      setHeaderTabToSetting,
      navigate,
      diamond,
      screenManager,
      setInitialDiamondOption,
      setHeaderTabDefault,
      setDiamondTabIconTrue,
      setStyleID,
      resetPath,
      changeBackClick,
    ]
  );

  const handleBack = useCallback(
    (label: string) => {
      changeBackClick(true);
      resetPath("");
      if (label === "SELECT DIAMOND") {
        if (Object.keys(diamond.details).length > 0 && footer.isShowFooter) {
          document.body.classList.add("sticky_filter");
        } else {
          document.body.classList.remove("sticky_filter");
        }
        setInitialOption();
        setInitialStartWithSettingData();
        setHeaderTabDefault();
        setInitialFooter();
        (screenManager as any).changeScreen({
          viewName: "diamond",
          id: null,
          styleId: "",
        });
      } else {
        // setInitialFooter();
        setInitialDiamondOption();
        setTableInitialState();
        setInitialFilter();
        if (footer.styleID.includes("RP")) {
          setHeaderTabToSetting("setting");
        } else {
          setHeaderTabToSetting("dyo");
        }
        (screenManager as any).changeScreen({
          viewName: "dyo",
          id: null,
          styleId: footer.styleID,
        });
      }
    },
    [
      document.body,
      setInitialOption,
      setInitialStartWithSettingData,
      setHeaderTabDefault,
      screenManager,
      setInitialFooter,
      setInitialDiamondOption,
      setHeaderTabToSetting,
      footer.styleID,
      setInitialFilter,
      setTableInitialState,
      resetPath,
      changeBackClick,
      diamond.details,
      footer.isShowFooter,
    ]
  );

  const tabData = [
    {
      heading: "Start With a Setting",
      imagePath: HKRing,
      imageAlt: "Start With a Setting",
      description: "Select a ring setting from our exclusive curation.",
      key: "setting",
      isVisible: openTab !== "setting",
    },
    {
      heading: "Design Your Own",
      imagePath: HKCustomize,
      imageAlt: "Design Your Own",
      description:
        "Design your own engagement ring by customizing your ring’s style, setting, metal and so much more.",
      key: "dyo",
      isVisible: openTab !== "dyo",
    },
  ];

  return {
    handleChangePath,
    tabData,
    handleBack,
  };
};

export default UseHeaderTab;
