import {LabelRangeSliderProps} from "../../types";
import {useCallback, useEffect, useMemo, useState} from "react";

const useLabelRangeSlider = (props: LabelRangeSliderProps) => {
    const {mark, keyName, handleFilterType, filter,removeFilterKey,rangeValue,setBackToSelectDiamondPage} = props;
    const marks: { [key: number]: string } = useMemo(() => {
            return mark.reduce((acc, val,index) => {
                // Merge each object into the accumulator
                return {...acc, [(100 / mark.length) * index]: val};
            }, {});
    }, [mark]);
    const getSortedKeys = (obj: { [key: number]: string }) => {
        return Object.keys(obj).map(Number).sort((a, b) => a - b);
    };
    const [value, setValue] = useState<[number, number]>([+(getSortedKeys(marks)[rangeValue ? +rangeValue?.[0] - 1 : 0]),+(getSortedKeys(marks)[rangeValue ? +rangeValue?.[1] - 1 : Object.keys(marks).length - 1])]);
    const [isFocus,setIsFocus] = useState<boolean>(false);
    const [defaultMinMax,setDefaultMinMax] = useState<[number,number]>([+(getSortedKeys(marks)[rangeValue ? +rangeValue?.[0] : 0]),+(getSortedKeys(marks)[rangeValue ? +rangeValue?.[1] : Object.keys(marks).length - 1])])


    const handleChangeFilterValue = useCallback((val: number | number[]): void=>{
        if (Array.isArray(val)) {
            const fromVal = Object.keys(marks)
                .sort((a, b) => (b > a ? -1 : 1))
                .indexOf(val[0].toString());
            const toVal = Object.keys(marks)
                .sort((a, b) => (b > a ? -1 : 1))
                .indexOf(val[1].toString());
            if((!(val[0] === value[0] && val[1] === value[1]) || document.querySelector('body.tabbing'))){
              handleFilterType(`${fromVal + 1}-${toVal + 1}`, keyName);
            }
        }
        if(Array.isArray(val) && (defaultMinMax[0] === val[0])&&(defaultMinMax[1] === val[1])){
            removeFilterKey(keyName)
        }
        // setChangeMinMaxValue(false);
        if(setBackToSelectDiamondPage){
            setBackToSelectDiamondPage(false);
        }
    },[marks,handleFilterType,value,removeFilterKey,keyName,setBackToSelectDiamondPage,defaultMinMax])

    const handleChange = useCallback((val: number | number[]): void => {
        if (Array.isArray(val)) {
            setValue(val as [number, number]);

            if(!isFocus){
                handleChangeFilterValue(val);
            }
        }
    }, [setValue,handleChangeFilterValue,isFocus]);

    const handleLabelChangeComplete = useCallback((val: number | number[]) => {
        handleChangeFilterValue(val)
    }, [handleChangeFilterValue]);

    // reset filter value when click reset button
    useEffect(() => {
        if (filter.isReset) {
            setValue([+Object.keys(marks)[rangeValue ? +rangeValue?.[0] - 1: 0],+Object.keys(marks)[rangeValue ? +rangeValue?.[1] : Object.keys(marks).length - 1]]);
            setDefaultMinMax([+Object.keys(marks)[rangeValue ? +rangeValue?.[0] : 0],+Object.keys(marks)[rangeValue ? +rangeValue?.[1] : Object.keys(marks).length - 1]])
        }
    }, [filter.isReset])
    const handleContextMenu = (event:any) => {
        event.preventDefault();
    };
    return {
        marks,
        handleLabelChangeComplete,
        value,
        handleChange,
        setIsFocus,
        handleContextMenu
    };
};
export default useLabelRangeSlider;
