import { StateManagerAction, StateManagerState } from '../types';
import StateManagerConstant from '../constant/StateManagerConstant';

const initialState: StateManagerState = {
  options: {
    path: '',
    id: '',
    currentScreen: '',
    currentView: '',
    selectedDiamond: '',
    selectedSetting: '',
    selectedDesign: '',
    cost: '',
  },
  editData: {}
};

const stateManagerReducer = (
  state = initialState,
  action: StateManagerAction,
): StateManagerState => {
  switch (action.type) {
    case StateManagerConstant.SET_PATH:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.key]: action.payload.value,
        },
      };
    case StateManagerConstant.SET_EDIT_DATA:
      return {
        ...state,
        editData : action.payload
      };
    default:
      return state;
  }
};

export default stateManagerReducer;
