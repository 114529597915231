import {MRT_RowData, MRT_TableInstance} from "../../types";
import React from "react";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
    table: MRT_TableInstance<TData>;
}

export const MRT_ExpandAllButton = <TData extends MRT_RowData>({ table,...rest}: Props<TData>) => {
    const {
        getCanSomeRowsExpand,
        getIsAllRowsExpanded,
        getIsSomeRowsExpanded,
        getState,
        options: {
            icons: {KeyboardDoubleArrowDownIcon},
            localization,
            muiExpandAllButtonProps,
            renderDetailPanel,
        },
        toggleAllRowsExpanded,
    } = table;
    const {density, isLoading} = getState();

    const iconButtonProps =
        muiExpandAllButtonProps instanceof Function
            ? muiExpandAllButtonProps({table})
            : muiExpandAllButtonProps;

    const isAllRowsExpanded = getIsAllRowsExpanded();

    return (
        <div className="headless_tooltip_bottom"
             data-tip={
                 iconButtonProps?.title ?? isAllRowsExpanded
                         ? localization.collapseAll
                         : localization.expandAll
             }>
            <button
                {...iconButtonProps}
                aria-label={localization.expandAll}
                disabled={
                    isLoading || (!renderDetailPanel && !getCanSomeRowsExpand())
                }
                className={classNames(
                    `headless_expandBtn-tooltipBtn`,
                    density === "compact"
                        ? "headless_hw-175"
                        : "headless_expandbtn-btn-wh"
                )}
                onClick={() => toggleAllRowsExpanded(!isAllRowsExpanded)}
            >
                {iconButtonProps?.children ?? (
                    <KeyboardDoubleArrowDownIcon
                        className={"h-5 w-5"}
                        style={{
                            transform: `rotate(${
                                isAllRowsExpanded ? -180 : getIsSomeRowsExpanded() ? -90 : 0
                            }deg)`,
                            transition: "transform 150ms",
                        }}
                    />
                )}
            </button>
        </div>
    )
}
