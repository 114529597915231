import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";

const useFilter = ({isSetHeight,footer}: {isSetHeight: boolean,footer:any }) => {
    const isLargeTablet = useMediaQuery({query: "(max-width: 767px)"});
    const defaultHeight = isLargeTablet ? 0 : 260;
    const [height, setHeight] = useState<string | number>(defaultHeight);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [isApplyToggleFilter, setIsApplyToggleFilter] = useState(false);
    const [isResetFilter , setIsResetFilter] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const [currentHeight,setCurrentHeight] = useState<number>(ref.current?.offsetHeight as number)

    useEffect(() => {
        setHeight(defaultHeight);
        // setToggleFilter(!isLargeTablet);
        setToggleFilter(false);
    }, [isLargeTablet])
    const [tabletHeight, setTabletHeight] = useState(0);

    useEffect(()=>{
        const rootStyle = (document.querySelector("#rootApp") as HTMLElement);
        if(rootStyle){
            !toggleFilter ? rootStyle.classList.remove('filterIsActive') : rootStyle.classList.add('filterIsActive');
        }
    },[toggleFilter])

    const calculateTabletHeight = useCallback(() => {
        const tabExistingHeight = window.innerHeight - (window.innerHeight > 470 ? (footer.stickyHeight +162) : 0);
        setTabletHeight(tabExistingHeight);
        setCurrentHeight(ref.current?.offsetHeight as number);
    }, []);

    useEffect(() => {
        calculateTabletHeight();
        const handleResize = () => {
            calculateTabletHeight();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [calculateTabletHeight]);

    useEffect(()=>{
        setCurrentHeight(ref.current?.offsetHeight as number);
    },[ref.current?.offsetHeight])

    /*useLayoutEffect(() => {
        if (isSetHeight) {
            if (currentHeight) {
                const isLayoutHeight = isLargeTablet ? +currentHeight < +height : +currentHeight > +height

                if (isLayoutHeight) {
                    setHeight(currentHeight);
                } else if (isApplyToggleFilter) {
                    if (toggleFilter) {
                        setHeight(isLargeTablet ? tabletHeight + "px" : defaultHeight);
                    } else if (currentHeight > defaultHeight) {
                        setHeight(defaultHeight);
                    }
                }
            }
        }
        if(toggleFilter && (currentHeight > defaultHeight) && (!isLargeTablet)){
            setHeight(currentHeight);
        }
    }, [toggleFilter, isSetHeight, tabletHeight,isLargeTablet,currentHeight]);*/

    useLayoutEffect(() => {
        // const currentHeight = ref.current?.offsetHeight;
        if (isSetHeight) {
            if (currentHeight) {
                if (+currentHeight < +height) {
                    setHeight(currentHeight);
                } else if (isApplyToggleFilter) {
                    if (toggleFilter) {
                        let desktopHeight = Math.min(currentHeight, height as number * 2);
                        // let windowInnertHeight = window.innerHeight;
                        // let tabExistingHeight = windowInnertHeight - (windowInnertHeight * .20);
                        // let tabletHeight = tabExistingHeight; /*80*/
                        setHeight(isLargeTablet ? tabletHeight + "px" : currentHeight);
                    } else if (currentHeight > defaultHeight) {
                        setHeight(defaultHeight);
                    }
                }
            }
        }
    }, [toggleFilter, isSetHeight, tabletHeight,currentHeight]);

    const handleFilters = useCallback(() => {
        setToggleFilter((toggle) => !toggle);
        setIsApplyToggleFilter(true);
    }, [setIsApplyToggleFilter, setToggleFilter]);

    return {
        height,
        handleFilters,
        ref,
        defaultHeight,
        toggleFilter,
        isLargeTablet,
        setIsResetFilter,
        isResetFilter
    };
};
export default useFilter;
