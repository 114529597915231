import React from "react";
import { MRT_ToggleFullScreenButton } from "../buttons/MRT_ToggleFullScreenButton";
import { MRT_ShowHideColumnsButton } from "../buttons/MRT_ShowHideColumnsButton";
import { MRT_ToggleDensePaddingButton } from "../buttons/MRT_ToggleDensePaddingButton";
import { MRT_ToggleFiltersButton } from "../buttons/MRT_ToggleFiltersButton";
import { MRT_ToggleGlobalFilterButton } from "../buttons/MRT_ToggleGlobalFilterButton";
import { MRT_RowData, MRT_TableInstance } from "../../types";

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}
export const MRT_ToolbarInternalButtons = <TData extends MRT_RowData>({
  table,
  ...rest
}: Props<TData>) => {
  const {
    options: {
      enableColumnFilters,
      enableColumnOrdering,
      enableDensityToggle,
      enableFilters,
      enableFullScreenToggle,
      enableGlobalFilter,
      enableHiding,
      enablePinning,
      initialState,
      renderToolbarInternalActions,
    },
  } = table;

  return (
    <div className={"headless_tInternal-btn"}>
      {renderToolbarInternalActions?.({
        table,
      }) ?? (
        <>
          {enableFilters &&
            enableGlobalFilter &&
            !initialState?.showGlobalFilter && (
              <MRT_ToggleGlobalFilterButton table={table} />
            )}
          {enableFilters && enableColumnFilters && (
            <MRT_ToggleFiltersButton table={table} />
          )}
          {(enableHiding || enableColumnOrdering || enablePinning) && (
            <MRT_ShowHideColumnsButton table={table} />
          )}
          {enableDensityToggle && (
            <MRT_ToggleDensePaddingButton table={table} />
          )}
          {enableFullScreenToggle && (
            <MRT_ToggleFullScreenButton table={table} />
          )}
        </>
      )}
    </div>
  );
};
