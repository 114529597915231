import React from "react";
import { MRT_GlobalFilterTextField } from "../inputs/MRT_GlobalFilterTextField";
import { MRT_TablePagination } from "./MRT_TablePagination";
import { MRT_ToolbarAlertBanner } from "./MRT_ToolbarAlertBanner";
import { MRT_ToolbarInternalButtons } from "./MRT_ToolbarInternalButtons";
import { MRT_ToolbarDropZone } from "./MRT_ToolbarDropZone";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { parseFromValuesOrFunc } from "../../column.utils";

export const commonToolbarStyles = () => ({
  alignItems: "flex-start",
  backgroundImage: "none",
  display: "grid",
  flexWrap: "wrap-reverse",
  minHeight: "3.5rem",
  overflow: "hidden",
  p: "0 !important",
  transition: "all 150ms ease-in-out",
  zIndex: 1,
});
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}
export const MRT_TopToolbar = <TData extends MRT_RowData>({
  table,
}: Props<TData>) => {
  const {
    getState,
    options: {
      enableGlobalFilter,
      enablePagination,
      enableToolbarInternalActions,
      muiTopToolbarProps,
      positionGlobalFilter,
      positionPagination,
      positionToolbarAlertBanner,
      positionToolbarDropZone,
      renderTopToolbarCustomActions,
    },
    refs: { topToolbarRef },
  } = table;

  const { isFullScreen, showGlobalFilter } = getState();

  const toolbarProps = parseFromValuesOrFunc(muiTopToolbarProps, { table });

  const stackAlertBanner = !!renderTopToolbarCustomActions || showGlobalFilter;

  return (
    <div
      {...toolbarProps}
      ref={(ref: HTMLDivElement) => {
        topToolbarRef.current = ref;
        if (toolbarProps?.ref) {
          toolbarProps.ref.current = ref;
        }
      }}
      className={classNames(
        `headless_topToolbar`,
        isFullScreen ? `headless_top-sticky` : ``
      )}
      style={{ ...commonToolbarStyles() }}
    >
      {positionToolbarAlertBanner === "top" && (
        <MRT_ToolbarAlertBanner
          stackAlertBanner={stackAlertBanner}
          table={table}
        />
      )}
      {["both", "top"].includes(positionToolbarDropZone ?? "") && (
        <MRT_ToolbarDropZone table={table} />
      )}
      <div
        className={classNames(
          "headless_toolbar-item",
          stackAlertBanner ? "headless_relative" : "headless_stackAlert"
        )}
      >
        {enableGlobalFilter && positionGlobalFilter === "left" && (
          <MRT_GlobalFilterTextField table={table} />
        )}
        {renderTopToolbarCustomActions?.({ table }) ?? <span />}
        {enableToolbarInternalActions ? (
          <div className={"headless_top-toolbar-filter"}>
            {enableGlobalFilter && positionGlobalFilter === "right" && (
              <MRT_GlobalFilterTextField table={table} />
            )}
            <MRT_ToolbarInternalButtons table={table} />
          </div>
        ) : (
          enableGlobalFilter &&
          positionGlobalFilter === "right" && (
            <MRT_GlobalFilterTextField table={table} />
          )
        )}
      </div>
      {enablePagination &&
        ["top", "both"].includes(positionPagination ?? "") && (
          <MRT_TablePagination table={table} position="bottom" />
        )}
    </div>
  );
};
