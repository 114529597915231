import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";
import * as React from "react";

interface Props<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const MRT_TableHeadCellFilterLabel = <TData extends MRT_RowData = {}>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  const {
    options: {
      columnFilterDisplayMode,
      icons: { FilterAltIcon },
      localization,
    },
    refs: { filterInputRefs },
    setShowColumnFilters,
  } = table;
  const { column } = header;
  const { columnDef } = column;

  const isRangeFilter =
    ["range", "ranger-slider"].includes(columnDef.filterVariant ?? "") ||
    ["between", "betweenInclusive", "inNumberRange"].includes(
      columnDef._filterFn
    );
  const filterValue = column.getFilterValue();
  const currentFilterOption = columnDef._filterFn;
  const isFilterActive =
    (Array.isArray(filterValue) && filterValue.some(Boolean)) ||
    (!!filterValue && !Array.isArray(filterValue));
  const filterTooltip =
    columnFilterDisplayMode === "popover" && !isFilterActive
      ? localization.filterByColumn?.replace(
          "{column}",
          String(columnDef.header)
        )
      : localization.filteringByColumn
          .replace("{column}", String(columnDef.header))
          .replace(
            "{filterType}",
            currentFilterOption
              ? // @ts-ignore
                localization[
                  `filter${
                    currentFilterOption?.charAt(0)?.toUpperCase() +
                    currentFilterOption?.slice(1)
                  }`
                ]
              : ""
          )
          .replace(
            "{filterValue}",
            `"${
              Array.isArray(filterValue)
                ? (filterValue as [string, string]).join(
                    `" ${isRangeFilter ? localization.and : localization.or} "`
                  )
                : (filterValue as string)
            }"`
          )
          .replace('" "', "");

  return (
    <>
      {" "}
      {(!!column.getFilterValue() && !isRangeFilter) ||
      (isRangeFilter && // @ts-ignore
        (!!column.getFilterValue()?.[0] || !!column.getFilterValue()?.[1])) ? (
        <span className={"flex-[0_0]"}>
          <div className="headless_tooltip_bottom" data-tip={filterTooltip}>
            <button
              className={"headless_expandBtn-tooltipBtn"}
              {...rest}
              onClick={(event) => {
                setShowColumnFilters(true);
                queueMicrotask(() => {
                  filterInputRefs.current[`${column.id}-0`]?.focus();
                  filterInputRefs.current[`${column.id}-0`]?.select();
                });
                event.stopPropagation();
              }}
            >
              <FilterAltIcon className={"h-6 w-6"} />
            </button>
          </div>
        </span>
      ) : null}
    </>
  );
};
