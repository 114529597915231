import React from 'react';
import Diamond from './Diamond';
import { PropsFromScreenManager } from '../../types';
import Header from '../Header';
import StickyFooter from '../StickyFooter';
import Screen from '../../Screen';

class DiamondScreen extends Screen {
  constructor(props: PropsFromScreenManager) {
    super(props);
  }

  render() {
    return (
      <>
        <Diamond instanceData={this.props.instanceData} />
      </>
    );
  }
}

export default DiamondScreen;
