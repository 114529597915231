import { MRT_EditActionButtons } from "./MRT_EditActionButtons";
import React, { useState } from "react";
import { MRT_Cell, MRT_Row, MRT_RowData, MRT_TableInstance } from "../../types";

interface Props<TData extends MRT_RowData> {
  cell: MRT_Cell<TData>;
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}

export const MRT_ToggleRowActionMenuButton = <TData extends MRT_RowData>({cell,row,table,...rest}: Props<TData>) => {
  const {
    getState,
    options: {
      createDisplayMode,
      editDisplayMode,
      enableEditing,
      icons: { EditIcon, MoreHorizIcon },
      localization,
      renderRowActionMenuItems,
      renderRowActions,
    },
    setEditingRow,
  } = table;

  const { creatingRow, editingRow } = getState();

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;

  const showEditActionButtons =
    (isCreating && createDisplayMode === "row") ||
    (isEditing && editDisplayMode === "row");

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenRowActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleStartEditMode = (event: React.MouseEvent) => {
    event.stopPropagation();
    setEditingRow({ ...row });
    setAnchorEl(null);
  };

  return (
    <>
      {renderRowActions && !showEditActionButtons ? (
        renderRowActions({ cell, row, table })
      ) : showEditActionButtons ? (
        <MRT_EditActionButtons row={row} table={table} />
      ) : !renderRowActionMenuItems &&
        (enableEditing instanceof Function
          ? enableEditing(row)
          : enableEditing) ? (
              <div className="headless_tooltip_bottom"
                   data-tip={localization.edit}
              >
                <button
                    className={"headless_expandBtn-tooltipBtn"}
                    onClick={handleStartEditMode}
                    aria-label={localization.edit}
                >
                  <EditIcon className={"h-6 w-6"} />
                </button>
              </div>
      ) : renderRowActionMenuItems ? (
          <div className="headless_tooltip_bottom"
               data-tip={localization.rowActions}
          >
            <button
                className={"headless_expandBtn-tooltipBtn"}
                onClick={handleOpenRowActionMenu}
                aria-label={localization.rowActions}
            >
              <MoreHorizIcon className={"h-6 w-6"} />
            </button>
          </div>
      ) : null}
    </>
  );
};
