import {MRT_RowData, MRT_TableInstance} from "../../types";
import * as React from "react";

interface Props<TData extends MRT_RowData> {
    table: MRT_TableInstance<TData>;
    title?: string;
}

export const MRT_ToggleDensePaddingButton = <TData extends MRT_RowData>({ table,title,...rest}: Props<TData>) => {
    const {
        getState,
        options: {
            icons: {DensityLargeIcon, DensityMediumIcon, DensitySmallIcon},
            localization,
        },
        setDensity,
    } = table;
    const {density} = getState();

    const handleToggleDensePadding = () => {
        const nextDensity =
            density === "comfortable"
                ? "compact"
                : density === "compact"
                ? "spacious"
                : "comfortable";
        setDensity(nextDensity);
    };
    return (
        <div className="headless_tooltip_bottom"
             data-tip={title ?? localization.toggleDensity}
        >
            <button
                {...rest}
                onClick={handleToggleDensePadding}
                aria-label={localization.toggleDensity}
                className={`headless_expandBtn-tooltipBtn`}
            >
                {density === "compact" ? (
                    <DensitySmallIcon className={"h-6 w-6"}/>
                ) : density === "comfortable" ? (
                    <DensityMediumIcon className={"h-6 w-6"}/>
                ) : (
                    <DensityLargeIcon className={"h-6 w-6"}/>
                )}
            </button>
        </div>
    );
};
