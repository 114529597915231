import React,{ Dispatch, SetStateAction, useRef, useState, DragEvent } from "react";
import { MRT_ColumnPinningButtons } from "../buttons/MRT_ColumnPinningButtons";
import { MRT_GrabHandleButton } from "../buttons/MRT_GrabHandleButton";
import { reorderColumn } from "../../column.utils";
import { MRT_Column, MRT_RowData, MRT_TableInstance } from "../../types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  allColumns: MRT_Column<TData>[];
  column: MRT_Column<TData>;
  hoveredColumn: MRT_Column<TData> | null;
  setHoveredColumn: Dispatch<SetStateAction<MRT_Column<TData> | null>>;
  table: MRT_TableInstance<TData>;
}
export const MRT_ShowHideColumnsMenuItems = <TData extends MRT_RowData>({
  allColumns,
  column,
  hoveredColumn,
  setHoveredColumn,
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: { enableColumnOrdering, enableHiding, enablePinning },
    setColumnOrder,
  } = table;
  const { columnOrder } = getState();
  const { columnDef } = column;
  const { columnDefType } = columnDef;
  const switchChecked =
    (columnDefType !== "group" && column && column.getIsVisible()) ||
    (columnDefType === "group" &&
      column &&
      column.getLeafColumns().some((col) => col.getIsVisible()));

  const handleToggleColumnHidden = (column: MRT_Column<TData>) => {
    if (columnDefType === "group") {
      column?.columns?.forEach?.((childColumn: MRT_Column<TData>) => {
        childColumn.toggleVisibility(!switchChecked);
      });
    } else {
      column.toggleVisibility();
    }
  };

  const menuItemRef = useRef<HTMLDivElement | null>(null);

  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e: DragEvent<HTMLButtonElement>) => {
    setIsDragging(true);
    e.dataTransfer.setDragImage(menuItemRef.current as HTMLElement, 0, 0);
  };

  const handleDragEnd = (_e: DragEvent<HTMLButtonElement>) => {
    setIsDragging(false);
    setHoveredColumn(null);
    if (hoveredColumn && column) {
      setColumnOrder(reorderColumn(column, hoveredColumn, columnOrder));
    }
  };

  const handleDragEnter = (_e: DragEvent) => {
    if (!isDragging && columnDef.enableColumnOrdering !== false && column) {
      setHoveredColumn(column);
    }
  };
  if (!columnDef.header) return null;
  return (
    <>
      <div
        ref={menuItemRef}
        onDragEnter={handleDragEnter}
        className={classNames(
          `headless_showHide-columnItem`,
          isDragging ? `headless_columnItem-drag` : `headless_opacity-100`,
          hoveredColumn?.id === column?.id
            ? `headless_columnItem-hover`
            : "none",
          // `px-[${(column.depth + 0.5) * 2}rem]`,
          `px-[1rem]`
        )}
      >
        <div className={"headless_columnItem-child"}>
          {columnDefType !== "group" &&
            enableColumnOrdering &&
            !allColumns.some(
              (col) => col && col.columnDef.columnDefType === "group"
            ) &&
            (columnDef.enableColumnOrdering !== false ? (
              <MRT_GrabHandleButton
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                table={table}
              />
            ) : (
              <div className={"w-7"} />
            ))}
          {enablePinning &&
            (column && column.getCanPin() ? (
              <MRT_ColumnPinningButtons column={column} table={table} />
            ) : (
              <div className={"headless_w-70"} />
            ))}
          {enableHiding && column ? (
            <div className={"headless_flex-center"}>
              <input
                type="checkbox"
                className="headless_column-checkbox"
                disabled={!column.getCanHide()}
                onChange={() => handleToggleColumnHidden(column)}
                checked={switchChecked}
              />
              <span>{columnDef.header}</span>
            </div>
          ) : (
            <p className={`headless_coulumMenu-text`}>{columnDef.header}</p>
          )}
        </div>
      </div>
      {column &&
        column.columns?.map((c, i) => (
          <MRT_ShowHideColumnsMenuItems
            allColumns={allColumns}
            column={c}
            hoveredColumn={hoveredColumn}
            key={`${i}-${c.id}`}
            setHoveredColumn={setHoveredColumn}
            table={table}
          />
        ))}
    </>
  );
};
