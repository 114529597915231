import React, {useCallback, useState} from 'react';
import {TooltipDatatype, useTooltipProps} from "../../types";
import {useMediaQuery} from "react-responsive";

const UseTooltip = ({tooltip}:useTooltipProps) => {
    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
    const [tooltipData, setTooltipData] = useState<TooltipDatatype>({})
    const [loading, setLoading] = useState(true);
    const isMobileLayout = useMediaQuery({ query: "(max-width: 450px)" });

    const iFrameHeight:{[key:string]:{[kye:string]:string}} = {
        Color:{
            default:'365px',
            mobileView:'380px'
        },
        Carat:{
            default:'300px',
            mobileView:'335px'
        },
        Clarity:{
            default:'440px',
            mobileView:'470px'
        },
        Cut:{
            default:'520px',
            mobileView:'515px'
        }
    }

    // Event handler for iframe onload event
    const handleLoad = () => {
        setLoading(false);
    };

    const closeDialogBox = useCallback(() => {
        setIsOpenDialog(false);
    }, [setIsOpenDialog])

    const openDialogBox = useCallback(() => {
        setIsOpenDialog(true);
    }, [setIsOpenDialog])

    const getTooltipData = (title: string) => {
       const engravingTitles:string[] = ['Engraving','First Band Engraving','Second Band Engraving'];
        if(!tooltip.isLoading){
            const filterData = tooltip.tooltipData.find((data: TooltipDatatype) =>data.code === (engravingTitles.includes(title)? 'Engraving':title)
            );
            if (filterData) {
                setTooltipData(filterData);
            }
        }
    }

    return {
        isOpenDialog,
        closeDialogBox,
        openDialogBox,
        tooltipData,
        getTooltipData,
        handleLoad,
        loading,
        isMobileLayout,
        iFrameHeight
    }
}

export default UseTooltip;