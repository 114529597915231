import React, { FC } from "react";
import { HKLogo, HKStar } from "../Images";
import classes from "./CMSPage.module.css";
import { Link } from "react-router-dom";
import { RootState } from "../../reducers/RootReducer";
import { setInitialOption } from "../../action/RingAction";
import {
  setInitialDiamondOption,
  setDiamondDetailsSuccess,
} from "../../action/DiamondAction";
import { connect, ConnectedProps } from "react-redux";
import {
  setHeaderTabDefault,
  setHeaderTabToSetting,
  setInitialHeader,
} from "../../action/HeaderAction";
import { setInitialFilter } from "../../action/FilterAction";
import { setTableInitialState } from "../../action/TableAction";
import { setInitialFooter } from "../../action/FooterAction";
import { setInitialStartWithSettingData } from "../../action/StartWithSettingFilterAction";
import { SetInitialTooltip } from "../../action/TooltipAction";
import UseCMSPage from "./useCMSPage";
import { PropsFromScreenManager } from "../../types";
import { setExpandedRow } from "../../action/TableAction";
import {
  setRingOptions,
  setSelectedRingDetails,
} from "../../action/RingAction";
import { setInitialCart } from "../../action/CartAction";
import { setFilter } from "../../action/FilterAction";

const mapStateToProps = (state: RootState) => ({
  filter: state.filter,
  table: state.table,
  ring: state.ring,
});

const mapDispatchToProps = {
  setInitialOption,
  setHeaderTabDefault,
  setHeaderTabToSetting,
  setInitialDiamondOption,
  setInitialFilter,
  setTableInitialState,
  setInitialFooter,
  setInitialHeader,
  setInitialStartWithSettingData,
  SetInitialTooltip,
  setDiamondDetailsSuccess,
  setExpandedRow,
  setRingOptions,
  setSelectedRingDetails,
  setInitialCart,
  setFilter,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;
const CMSPage: FC<PropsFromRedux & PropsFromScreenManager> = (props) => {
  const { ContentArray } = UseCMSPage(props);
  /* useEffect(() => {
        if (screenManager) {
            (screenManager as any).props.setStateManagerData(
                'currentScreen',
                'CMS Screen',
            );
            (screenManager as any).props.setStateManagerData(
                'currentView',
                'Main View',
            );
        }
    }, []);*/
  /*const handleResetState = (key: string,path:string) => {
        if (key === 'Start With a Diamond') {
            setHeaderTabDefault();
        } else if (key === 'Start With a Setting' || key === 'Design Your Own') {
            setHeaderTabToSetting(key);
        }
        setInitialOption();
        setInitialDiamondOption();
        setInitialFilter();
        setTableInitialState();
        if (
            screenManager &&
            (screenManager as unknown as ScreenManagerABC).changeScreen
        ) {
            (screenManager as unknown as ScreenManagerABC).changeScreen({
                viewName: path,
                id: null,
            });
        }
    };*/
  return (
    <>
      <main className={classes.root}>
        <section className={classes.section}>
          <div
            className={`${classes.w_layout_blockContainer} ${classes.container} ${classes.w_container}`}
          >
            <div className={classes.logo}>
              <img
                src={HKLogo}
                loading="lazy"
                width={320}
                alt={"Customise Ring"}
                className={classes.logo_img}
              />
            </div>
            <div className={classes.ring_builder}>
              <h1 className={classes.title}>CUSTOMIZE YOUR ENGAGEMENT RING</h1>
              <h2 className={classes.subtitle}>Your Ring, Your Way</h2>
            </div>
            <div className={classes.bar}>
              <img
                src={HKStar}
                loading="lazy"
                width={160}
                alt={"Setting"}
                className={classes.barImg}
              />
            </div>
            <div className={classes._3section}>
              {ContentArray.map((content, index) => {
                return (
                  <div
                    tabIndex={0}
                    className={`${classes._3section_wrapper}`}
                    key={index}
                    onClick={() => {
                      content.onClick(content.linkTO, content.styleId);
                    }}
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                      if (event.code === "Enter" || event.code === "Space") {
                        content.onClick(content.linkTO, content.styleId);
                      }
                    }}
                  >
                    <div className={classes._3section_img_wrapper}>
                      <img
                        src={content.imagePath}
                        loading="lazy"
                        alt={content.imageAlt}
                      />
                    </div>
                    <div className={classes._3section_text_wrapper}>
                      <h2 className={classes._3section_title}>
                        {content.heading}
                      </h2>
                      <p className={classes._3section_para}>
                        {content.paragraph}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default connector(CMSPage);
