import React from "react";
import classes from "./HeadCenterTab.module.css";
import { HeaderCenterProps, HeaderTabProps } from "../../../types";
import HeaderTab from "../HeaderTab";

const HeadCenterTab = ({
  tabData,
  instanceData,
  isDisabledTab,
  iframeLoadActionCount,
}: HeaderCenterProps) => {
  return (
    <>
      <nav className={classes.nav} aria-label="Tabs">
        {tabData.map((tab: HeaderTabProps, index: number) => {
          return (
            <HeaderTab
              label={tab.label}
              isActive={tab.isActive}
              isDisabled={tab.isDisabled}
              isIcon={tab.isIcon}
              openTab={tab.openTab}
              backButton={tab.backButton}
              key={index}
              instanceData={instanceData}
              isDisabledTab={isDisabledTab}
              iframeLoadActionCount={iframeLoadActionCount}
            />
          );
        })}
      </nav>
    </>
  );
};

export default HeadCenterTab;
