import React, {FC} from "react";
import classes from "./DiamondOtherDetails.module.css";
import {DiamondOtherDetailsProps} from "../../types";
import Icon from "../Icons/Icon";
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";

const DiamondOtherDetails: FC<DiamondOtherDetailsProps> = (props) => {
    const {diamondDetails} = props;
    const {
        certificate_link,
        scs_certificate_link,
        color,
        clarity,
        cut,
        polish,
        symmetry,
        fluorecence,
        measurement,
        table,
        culet,
        girdle,
        depth,
        shape,
        // diamond_price,
        carat,
        certification
    } = diamondDetails;

    const newDiamondDetails = {
        certificate_link,
        scs_certificate_link,
        certification,
        shape,
        carat,
        // diamond_price,
        color,
        cut,
        clarity,
        fluorecence,
        polish,
        symmetry,
        table,
        depth,
        measurement,
        girdle,
        culet
    }

    return (
        <>
            {Object.entries(newDiamondDetails).map(([key, value]) => {
                if(value && value !== "null" && value !== 'undefined' && value !== ' '){
                    return (
                        <div key={key} className={classes.details}>
                            <span className={classes.label}>{key === 'scs_certificate_link' ? 'SCS Certificate Link' : key.replaceAll('_', ' ')}</span>
                            {
                                key === 'certificate_link' || key === 'scs_certificate_link'
                                    ? value ? <a
                                        href={value as string}
                                        target="_blank"
                                        className={`${classes.span} ${classes.spanLink}`}
                                    >
                                        <span className={'text-padding-top'}>View Certificate</span>
                                        <Icon name="hk-arrow-up-forward" />
                                    </a> : <span className={classes.span}>N/A</span>
                                    : <span className={classes.span}>
                                        {(key === 'diamond_price' && value) ? `$${ToLocalStringConvert(+value)}` : value}
                                        {(key === 'table' || key === 'depth') && (value) && '%'}
                                    </span>
                            }
                        </div>
                    )
                }
            })}
        </>
    );
};

export default DiamondOtherDetails;
