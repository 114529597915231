export default {
    SET_HEADER_TAB_TO_SETTING_OWN: "SET_HEADER_TAB_TO_SETTING_OWN",
    SET_HEADER_TAB_TO_DEFAULT: "SET_HEADER_TAB_TO_DEFAULT",
    SET_HEADER_TAB_DIAMOND_ICON: "SET_HEADER_TAB_DIAMOND_ICON",
    SET_HEADER_TAB_SETTING_ICON:"SET_HEADER_TAB_SETTING_ICON",
    SET_INITIAL_HEADER : 'SET_INITIAL_HEADER',
    SET_BOTH_TAB_ICONE_TRUE:'SET_BOTH_TAB_ICONE_TRUE',
    SET_PREVIOUS_URL_PATH : 'SET_PREVIOUS_URL_PATH',
    RESET_JOURNEY_PATH:'RESET_JOURNEY_PATH',
    CHANGE_BACK_CLICK:'CHANGE_BACK_CLICK'
}