import { useEffect, useRef } from "react";
import {UseStickyFooterType} from "../../types";

const useStickyFooter = ({
  setFooterToggle,
  setFooterHeight,
  footer,
  diamond,
  ring
}:UseStickyFooterType) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateWrapperHeight = () => {
      if (wrapperRef.current) {
        const wrapHeight = wrapperRef.current.clientHeight;
        setFooterHeight(wrapHeight)
      }
    };
    updateWrapperHeight();

    const handleResize = () => {
      updateWrapperHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [diamond.details, ring.selectedRingDetails,wrapperRef.current]);

  useEffect(() => {
    const body = document.body;
    const className = 'byo_page_present';
    if (body.classList.contains(className)) {
      body.classList.remove(className);
    }
  }, [footer.toggle]);
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if(process.env.NODE_ENV !== "production" && event.key === 'Escape' && footer.toggle){
          setFooterToggle();
          event.preventDefault();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [footer]);
  
  const handleClick = (): void => {
    setFooterToggle();
  };

  useEffect(()=>{
    if(wrapperRef.current){
      setFooterHeight(wrapperRef.current.clientHeight);
    }
  },[wrapperRef.current,footer.isShowFooter,diamond.details, ring.selectedRingDetails])

  return {
    wrapperRef,
    handleClick
  };
};

export default useStickyFooter;
