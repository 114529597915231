export default {
    TABLE_DATA_SUCCESS: 'DATA_SUCCESS',
    TABLE_DATA_LOADING:'DATA_LOADING',
    TABLE_DATA_FAIL:'DATA-FAIL',
    RECOMMENDED_DATA_SUCCESS: 'RECOMMENDED_DATA_SUCCESS',
    RECOMMENDED_FAIL: 'RECOMMENDED_FAIL',
    RECOMMENDED_LOADING: 'RECOMMENDED_LOADING',
    RECENT_DATA_SUCCESS: 'RECENT_DATA_SUCCESS',
    ACTIVE_TAB_INDEX: 'ACTIVE_TAB_INDEX',
    EXPANDED_ROW_WITH_ACTIVE_TAB: 'EXPANDED_ROW_WITH_ACTIVE_TAB',
    INITIAL_TABLE_STATE: 'INITIAL_TABLE_STATE',
    COMPARE_DATA_SUCCESS: 'COMPARE_DATA_SUCCESS',
    COMPARE_DATA_DELETE : 'COMPARE_DATA_DELETE',
    TOGGLE_SCROLL_TO_TOP : 'TOGGLE_SCROLL_TO_TOP',
    SET_TABLE_PAGINATION: 'SET_TABLE_PAGINATION',
    SET_IS_REFECHING: 'SET_IS_REFECHING',
    SET_SORTING:'SET_SORTING',
    SET_RECOMMENDED_DATA_DEFAULT:"SET_RECOMMENDED_DATA_DEFAULT"

};
