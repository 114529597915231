import {combineReducers} from "redux";
import filterReducer from "./FilterReducer";
import tableReducer from "./TableReducer";
import diamondReducer from "./DiamondReducer";
import ringReducer from "./RingReducer";
import headerReducer from './HeaderReducer';
import startWithSettingFilterReducer from "./StartWithSettingFilterReducer";
import footerReducer from "./FooterReducer";
import tooltipReducer from "./TooltipReducer";
import stateManagerReducer from './StateManagerReducer';
import cartReducer from "./CartReducer";

const RootReducer = combineReducers({
    filter: filterReducer,
    header: headerReducer,
    table: tableReducer,
    diamond: diamondReducer,
    ring: ringReducer,
    settingFilter: startWithSettingFilterReducer,
    tooltip:tooltipReducer,
    footer: footerReducer,
    stateManager: stateManagerReducer,
    cart: cartReducer
});
export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer
