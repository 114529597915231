import React from "react";
import { MRT_FilterRangeFields } from "../inputs/MRT_FilterRangeFields";
import { MRT_FilterTextField } from "../inputs/MRT_FilterTextField";
import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";

interface Props<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}
export const MRT_TableHeadCellFilterContainer = <TData extends MRT_RowData>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  const { getState } = table;
  const { showColumnFilters } = getState();
  const { column } = header;
  const { columnDef } = column;
  return (
    <>
      {showColumnFilters ? (
        <div>
          {columnDef.filterVariant === "range" ||
          ["between", "betweenInclusive", "inNumberRange"].includes(
            columnDef._filterFn
          ) ? (
            <MRT_FilterRangeFields header={header} table={table} />
          ) : (
            <MRT_FilterTextField header={header} table={table} />
          )}
        </div>
      ) : null}
    </>
  );
};
