import React, {FC} from 'react';
import DialogBox from '../DialogBox';
import UseTooltip from './useTooltip';
import {TooltipProps} from '../../types';
import Icon from '../Icons';
import MetalColorTooltip from '../MetalColorTooltip';
import {RootState} from '../../reducers/RootReducer';
import {connect, ConnectedProps} from 'react-redux';
import classes from './Tooltip.module.css';
import Loader from '../Loader/Loader';

const mapStateToProps = (state: RootState) => ({
  tooltip: state.tooltip
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromTooltipRedux = ConnectedProps<typeof connector>;

const Tooltip: FC<TooltipProps> = ({title, page, tooltipPadding, tooltip}) => {
  const {
    tooltipData,
    isOpenDialog,
    openDialogBox,
    closeDialogBox,
    getTooltipData,
    handleLoad,
    loading,
    isMobileLayout,
    iFrameHeight
  } = UseTooltip({tooltip});

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation()
          getTooltipData((page === 'pdpBuilder' && title === 'Color') ? 'Metal Color' : title);
          openDialogBox();
        }}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            event.stopPropagation()
            getTooltipData((page === 'pdpBuilder' && title === 'Color') ? 'Metal Color' : title);
            openDialogBox();
          }
        }}
        aria-label={"Information about " + tooltipData.title}
        role={"tooltip"}
        tabIndex={0}
        className={classes.iconWrp}
      >
        <Icon name={"hk_help"} className={classes.iconColor}/>
      </div>
      <DialogBox
        isOpen={isOpenDialog}
        closeModal={closeDialogBox}
        isIcon={true}
        title={tooltipData && tooltipData.title as string}
      >
        <div
          className={classes.dialogBody}
          style={{padding: `${!tooltipPadding ? '1rem' : tooltipPadding}`}}
        >
          <div className={classes.dialogDetail}>
            <div className={classes.dialogText} id={'tooltipdata_content'}>
              {
                (tooltipData.content !== 'null data') &&
                <p className={classes.dialogParagraph}>
                  {tooltipData.content}
                </p>
              }
              {
                (tooltipData.type === 'scs') &&
                <div className={classes.listDatatype} dangerouslySetInnerHTML={{__html: tooltipData.scs as string}}/>
              }
              {
                ((tooltipData.file_path && tooltipData.file_path !== "null data")) ?
                  (
                    <>
                      {loading ? <Loader/> : ''}
                      <iframe
                        title={tooltipData.title}
                        src={tooltipData.file_path}
                        height={
                          (isMobileLayout)
                            ? (iFrameHeight[tooltipData.title as string]['mobileView'])
                            : (iFrameHeight[tooltipData.title as string]['default'])
                        }
                        frameBorder="0"
                        onLoad={handleLoad}
                      />
                    </>
                  ) : ''
              }
              {
                (tooltipData.link !== 'null data' && tooltipData.link) &&
                <a className="underline text-right text-black mt-1 mr-5"
                   href={tooltipData.link}
                   target="_blank">Learn More
                </a>
              }
              {
                ((page && page === 'pdpBuilder') && (['Metal', 'Crown Metal'].includes(title)) || ['Metal Color', 'Crown Metal'].includes(tooltipData.code as string)) ?
                  <MetalColorTooltip/>
                  : null
              }
            </div>
          </div>
        </div>
      </DialogBox>
    </>
  )
};

export default connector(Tooltip);
