// StorePage.tsx
import React, { FC } from "react";
import classes from "./StorePage.module.css";
import { PropsFromScreenManager } from "../../types";
import useStorePage from "./useStorePage";
import { RootState } from "../../reducers/RootReducer";
import {
  setInitialOption,
  setRingOptions,
  setSelectedRingDetails,
} from "../../action/RingAction";
import {
  setHeaderTabDefault,
  setHeaderTabToSetting,
  setInitialHeader,
} from "../../action/HeaderAction";
import {
  setDiamondDetailsSuccess,
  setInitialDiamondOption,
} from "../../action/DiamondAction";
import { setFilter, setInitialFilter } from "../../action/FilterAction";
import { setExpandedRow, setTableInitialState } from "../../action/TableAction";
import { setInitialFooter } from "../../action/FooterAction";
import { setInitialStartWithSettingData } from "../../action/StartWithSettingFilterAction";
import { SetInitialTooltip } from "../../action/TooltipAction";
import { setInitialCart } from "../../action/CartAction";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = (state: RootState) => ({
  filter: state.filter,
  table: state.table,
  ring: state.ring,
});

const mapDispatchToProps = {
  setInitialOption,
  setHeaderTabDefault,
  setHeaderTabToSetting,
  setInitialDiamondOption,
  setInitialFilter,
  setTableInitialState,
  setInitialFooter,
  setInitialHeader,
  setInitialStartWithSettingData,
  SetInitialTooltip,
  setDiamondDetailsSuccess,
  setExpandedRow,
  setRingOptions,
  setSelectedRingDetails,
  setInitialCart,
  setFilter,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

const StorePage: FC<PropsFromRedux & PropsFromScreenManager> = (props) => {
  const { ContentArray } = useStorePage(props);
  return (
    <main className={classes.root}>
      <div className={classes.videoBackground}>
        <iframe
          src="https://player.vimeo.com/video/1017567408?background=1&autoplay=1&loop=1&muted=1"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          className={classes.videoIframe}
          title="Background Video"
        ></iframe>
      </div>
      <section className={classes.section}>
        <div className={classes.container}>
          <picture className={classes.logo}>
            {/* <img src={logo} className="max-w-96" alt="logo" /> */}
          </picture>

          <div className={classes.actions}>
            {ContentArray.map((content, index) => {
              return (
                <div
                  tabIndex={0}
                  className={classes.cta}
                  key={index}
                  onClick={() => {
                    content.onClick(content.link, content.styleId);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.code === "Enter" || event.code === "Space") {
                      content.onClick(content.link, content.styleId);
                    }
                  }}
                >
                  <span className={classes.heading}>{content.heading}</span>
                </div>
              );
            })}
          </div>
          {/* Use instanceData here if needed */}
        </div>
      </section>
    </main>
  );
};

export default connector(StorePage);
