import {ArrowPathIcon as CircularProgress} from "@heroicons/react/24/solid";
import {MRT_Row, MRT_RowData, MRT_TableInstance} from "../../types";
import React from "react";

interface Props<TData extends MRT_RowData> {
    row: MRT_Row<TData>;
    table: MRT_TableInstance<TData>;
    variant?: "icon" | "text";
}

export const MRT_EditActionButtons = <TData extends MRT_RowData>({ row,table,variant = "icon",}: Props<TData>) => {
    const {
        getState,
        options: {
            icons: {CancelIcon, SaveIcon},
            localization,
            onCreatingRowCancel,
            onCreatingRowSave,
            onEditingRowSave,
            onEditingRowCancel,
        },
        refs: {editInputRefs},
        setCreatingRow,
        setEditingRow,
    } = table;
    const {creatingRow, editingRow, isSaving} = getState();

    const isCreating = creatingRow?.id === row.id;
    const isEditing = editingRow?.id === row.id;

    const handleCancel = () => {
        if (isCreating) {
            onCreatingRowCancel?.({row, table});
            setCreatingRow(null);
        } else if (isEditing) {
            onEditingRowCancel?.({row, table});
            setEditingRow(null);
        }
    };

    const handleSubmitRow = () => {
        //look for auto-filled input values
        Object.values(editInputRefs?.current)
            .filter((inputRef) => row.id === inputRef?.name?.split("_")?.[0])
            ?.forEach((input) => {
                if (
                    input.value !== undefined &&
                    Object.hasOwn(row?._valuesCache, input.name)
                ) {
                    // @ts-ignore
                    row._valuesCache[input.name] = input.value;
                }
            });
        if (isCreating)
            onCreatingRowSave?.({
                exitCreatingMode: () => setCreatingRow(null),
                row,
                table,
                values: row._valuesCache,
            });
        else if (isEditing) {
            onEditingRowSave?.({
                exitEditingMode: () => setEditingRow(null),
                row,
                table,
                values: row?._valuesCache,
            });
        }
    };
    return (
        <div className={"headless_editActtion_button"} onClick={(e) => e.stopPropagation()}>
            {variant === "icon" ? (
                <>
                    <div
                        className="headless_tooltip_bottom"
                        data-tip={localization.cancel}
                    >
                        <button
                            className={"headless_btn_transparent"}
                            onClick={handleCancel}
                        >
                            <CancelIcon className={"h-6 w-6"}/>
                        </button>
                    </div>
                    <div
                        className="headless_tooltip_bottom"
                        data-tip={localization.save}
                    >
                        <button
                            className={"headless_btn_transparent"}
                            onClick={handleCancel}
                            aria-label={localization.save}
                        >
                            {isSaving ? (
                                <CircularProgress className={"h-6 w-6"}/>
                            ) : (
                                <SaveIcon className={"h-6 w-6"}/>
                            )}
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <button onClick={handleCancel} className={"headless_btn_primary"}>
                        {localization.cancel}
                    </button>
                    <button onClick={handleSubmitRow} className={"headless_btn_primary"}>
                        {isSaving && <CircularProgress className={"h-6 w-6"}/>}
                        {localization.save}
                    </button>
                </>
            )}
        </div>
    );
};
