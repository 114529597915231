export const compareObjects = (obj1:any, obj2:any) => {
  // If both objects are exactly the same object, return true
  if (obj1 === obj2) return true;

  // If both objects are not objects or one of them is null, return false
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If the number of keys is not the same, return false
  if (keys1.length !== keys2.length) return false;

  // Check each key and value recursively
  for (let key of keys1) {
    if (!keys2.includes(key) || !compareObjects(obj1[key], obj2[key])) return false;
  }

  // Objects are equal
  return true;
}

