import React,{ Fragment } from "react";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { parseFromValuesOrFunc } from "../../column.utils";
import { XCircleIcon } from "@heroicons/react/24/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  stackAlertBanner?: boolean;
  table: MRT_TableInstance<TData>;
}

export const MRT_ToolbarAlertBanner = <TData extends MRT_RowData>({
  stackAlertBanner,
  table,
  ...rest
}: Props<TData>) => {
  const {
    getPrePaginationRowModel,
    getSelectedRowModel,
    getState,
    options: {
      localization,
      muiToolbarAlertBannerProps,
      muiToolbarAlertBannerChipProps,
      positionToolbarAlertBanner,
      rowCount,
    },
  } = table;
  const { grouping, showAlertBanner } = getState();
  const alertProps = {
    ...parseFromValuesOrFunc(muiToolbarAlertBannerProps, {
      table,
    }),
    ...rest,
  };

  const chipProps = parseFromValuesOrFunc(muiToolbarAlertBannerChipProps, {
    table,
  });

  const selectMessage =
    getSelectedRowModel().rows.length > 0
      ? localization.selectedCountOfRowCountRowsSelected
          ?.replace(
            "{selectedCount}",
            getSelectedRowModel().rows.length.toString()
          )
          ?.replace(
            "{rowCount}",
            (rowCount ?? getPrePaginationRowModel().rows.length).toString()
          )
      : null;

  const groupedByMessage =
    grouping.length > 0 ? (
      <span>
        {localization.groupedBy}{" "}
        {grouping.map((columnId, index) => (
          <Fragment key={`${index}-${columnId}`}>
            {index > 0 ? localization.thenBy : ""}
            <span className="headless_badge">
              {table.getColumn(columnId).columnDef.header}
              <XCircleIcon
                className={"headless_badge-crossIcone"}
                fill={"#d4c2fd"}
                width={24}
                height={24}
                onClick={() => table.getColumn(columnId).toggleGrouping()}
              />
            </span>
          </Fragment>
        ))}
      </span>
    ) : null;

  return (
    <>
      {showAlertBanner || !!selectMessage || !!groupedByMessage ? (
        <div
          className={classNames(
            `headless_multi-badges`,
            stackAlertBanner
              ? `headless_mb-0`
              : positionToolbarAlertBanner === "bottom"
              ? "headless_mb--1"
              : ""
          )}
          {...alertProps}
        >
          <div className="flex">
            <div className="headless_multi-badgeItem">
              {alertProps?.title && <>{alertProps.title}</>}
              <div className={"headless_py-2"}>
                <div className={"headless_p-05_1"}>
                  {alertProps?.children}
                  {alertProps?.children &&
                    (selectMessage || groupedByMessage) && <br />}
                  {selectMessage}
                  {selectMessage && groupedByMessage && <br />}
                  {groupedByMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
