import {MRT_RowData, MRT_TableInstance} from "../../types";
import * as React from "react";

interface Props<TData extends MRT_RowData> {
    table: MRT_TableInstance<TData>;
    title?: string;
}

export const MRT_ToggleFiltersButton = <TData extends MRT_RowData>({table,title,...rest}: Props<TData>) => {
    const {
        getState,
        options: {
            icons: {FilterListIcon, FilterListOffIcon},
            localization,
        },
        setShowColumnFilters,
    } = table;
    const {showColumnFilters} = getState();

    const handleToggleShowFilters = () => {
        setShowColumnFilters(!showColumnFilters);
    };

    return (
        <div className="headless_tooltip_bottom"
             data-tip={title ?? localization.showHideFilters}
        >
            <button
                className={"headless_expandBtn-tooltipBtn"}
                {...rest}
                onClick={handleToggleShowFilters}
                aria-label={localization.showHideFilters}
            >
                {showColumnFilters ? (
                    <FilterListOffIcon className={"h-6 w-6"}/>
                ) : (
                    <FilterListIcon className={"h-6 w-6"}/>
                )}
            </button>
        </div>
    );
};
