import React, { useMemo } from "react";
import { MRT_TableHeadCellColumnActionsButton } from "./MRT_TableHeadCellColumnActionsButton";
import { MRT_TableHeadCellFilterContainer } from "./MRT_TableHeadCellFilterContainer";
import { MRT_TableHeadCellFilterLabel } from "./MRT_TableHeadCellFilterLabel";
import { MRT_TableHeadCellGrabHandle } from "./MRT_TableHeadCellGrabHandle";
import { MRT_TableHeadCellResizeHandle } from "./MRT_TableHeadCellResizeHandle";
import { MRT_TableHeadCellSortLabel } from "./MRT_TableHeadCellSortLabel";
import { getCommonCellStyles } from "../../column.utils";
import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}
export const MRT_TableHeadCell = <TData extends MRT_RowData>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  const theme = "light";
  const {
    getState,
    options: {
      enableColumnActions,
      enableColumnDragging,
      enableColumnOrdering,
      enableGrouping,
      enableMultiSort,
      layoutMode,
      muiTableHeadCellProps,
    },
    refs: { tableHeadCellRefs },
    setHoveredColumn,
  } = table;
  const {
    density,
    draggingColumn,
    grouping,
    hoveredColumn,
    showColumnFilters,
    setBackToSelectDiamondPage,
  } = getState();
  //@ts-ignore
  const { column } = header;
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const mTableHeadCellProps =
    muiTableHeadCellProps instanceof Function
      ? muiTableHeadCellProps({ column, table })
      : muiTableHeadCellProps;

  const mcTableHeadCellProps =
    columnDef.muiTableHeadCellProps instanceof Function
      ? columnDef.muiTableHeadCellProps({ column, table })
      : columnDef.muiTableHeadCellProps;

  const tableCellProps = {
    ...mTableHeadCellProps,
    ...mcTableHeadCellProps,
  };

  const showColumnActions =
    (enableColumnActions || columnDef.enableColumnActions) &&
    columnDef.enableColumnActions !== false;

  const showDragHandle =
    enableColumnDragging !== false &&
    columnDef.enableColumnDragging !== false &&
    (enableColumnDragging ||
      (enableColumnOrdering && columnDef.enableColumnOrdering !== false) ||
      (enableGrouping &&
        columnDef.enableGrouping !== false &&
        !grouping.includes(column.id)));

  const headerPL = useMemo(() => {
    let pl = 0;
    if (column.getCanSort()) pl++;
    if (showColumnActions) pl += 1.75;
    if (showDragHandle) pl += 1.25;
    return pl;
  }, [showColumnActions, showDragHandle]);

  const draggingBorder = useMemo(
    () =>
      draggingColumn?.id === column.id
        ? `1px dashed rgba(0,0,0,0.5)`
        : hoveredColumn?.id === column.id
        ? `2px dashed #1976d2`
        : undefined,
    [draggingColumn, hoveredColumn]
  );

  const draggingBorders = draggingBorder
    ? {
        borderLeft: draggingBorder,
        borderRight: draggingBorder,
        borderTop: draggingBorder,
      }
    : undefined;

  const handleDragEnter = (_e: Event) => {
    if (enableGrouping && hoveredColumn?.id === "drop-zone") {
      setHoveredColumn(null);
    }
    if (enableColumnOrdering && draggingColumn && columnDefType !== "group") {
      setHoveredColumn(
        columnDef.enableColumnOrdering !== false ? column : null
      );
    }
  };

  const headerElement =
    columnDef?.Header instanceof Function
      ? columnDef?.Header?.({
          column,
          header,
          table,
        })
      : columnDef?.Header ?? columnDef.header;
  return (
    <td
      className={classNames(
        `headless_thead-td`,
        columnDefType === "group"
          ? `headless_text-center`
          : `headless_textLeft`,
        layoutMode === "grid" ? `headless_flex-col` : ``,
        density === "compact"
          ? "headless_p-2"
          : density === "comfortable"
          ? columnDefType === "display"
            ? "headless_p-3 "
            : "headless_p-05"
          : columnDefType === "display"
          ? "headless_p-1_125"
          : "headless_p-6",
        columnDefType === "display"
          ? "pb-0"
          : showColumnFilters || density === "compact"
          ? "headless_pb-04"
          : "headless_pb-2",
        columnDefType === "group" || density === "compact"
          ? "headless_pt-2"
          : density === "comfortable"
          ? "headless_pt-2"
          : "headless_pt-5",
        enableMultiSort && column.getCanSort() ? `select-none` : ``,
        column.getIsResizing() || draggingColumn?.id === column.id
          ? `headless_z-3`
          : column.getIsPinned() && columnDefType !== "group"
          ? `headless_z-2`
          : `headless_z-1`,
        column.getIsSorted() ? "Sorted" : "",
        !headerElement ? "isNull" : ""
      )}
      colSpan={(header ?? header)?.colSpan}
      onDragEnter={handleDragEnter}
      ref={(node) => {
        if (node) {
          tableHeadCellRefs.current[column.id] = node;
        }
      }}
      {...tableCellProps}
      style={{
        ...getCommonCellStyles({
          column,
          header,
          table,
        }),
      }}
    >
      {header.isPlaceholder ? null : (
        <div
          className={classNames(
            `headless_thead-div`,
            tableCellProps?.align === "right"
              ? `headless_flex-row-reverse`
              : `headless_flex-row`,
            columnDefType === "group" || tableCellProps?.align === "center"
              ? "headless_justify-center"
              : column.getCanResize()
              ? "headless_justify-between"
              : "headless_justify-between"
          )}
        >
          <div onClick={() => setBackToSelectDiamondPage(false)}>
            <div
              className={classNames(
                `headless_center-flex-gap1`,
                column.getCanSort() && columnDefType !== "group"
                  ? `headless_cursor-pointer`
                  : ``,
                tableCellProps?.align === "right"
                  ? `headless_flex-row-reverse`
                  : `headless_flex-row`,
                columnDefType === "data" ? "headless_overflow-visible" : ``,
                tableCellProps?.align === "center" ? `pl-[${headerPL}rem]` : ``
              )}
              onClick={
                columnDef.isNotShowSorting
                  ? () => {}
                  : column.getToggleSortingHandler()
              }
            >
              <div
                className={classNames(
                  columnDefType === "data" ? "headless_overflow-visible" : ``,
                  (columnDef.header?.length ?? 0) < 20
                    ? "headless_whitespace-nowrap"
                    : "headless_whitespace-nowrap",
                  `headless_text-ellipsis min-w-[${Math.min(
                    columnDef.header?.length ?? 0,
                    5
                  )}ch] hover:text-clip`
                )}
                title={columnDefType === "data" ? columnDef.header : undefined}
              >
                {headerElement}
              </div>
              {column.getCanSort() && !columnDef?.isNotShowSorting && (
                <MRT_TableHeadCellSortLabel
                  header={header}
                  table={table}
                  tableCellProps={tableCellProps}
                />
              )}
              {column.getCanFilter() && (
                <MRT_TableHeadCellFilterLabel header={header} table={table} />
              )}
            </div>
          </div>
          <div className={"headless_flex-center"}>
            {columnDefType !== "group" && (
              <div className="headless_thead-cellgrab">
                {/*{showDragHandle && (
                  <MRT_TableHeadCellGrabHandle
                    column={column}
                    table={table}
                    tableHeadCellRef={{
                      current: tableHeadCellRefs.current[column.id],
                    }}
                  />
                )}*/}
                {showColumnActions && (
                  <MRT_TableHeadCellColumnActionsButton
                    header={header}
                    table={table}
                  />
                )}
              </div>
            )}
            {column.getCanResize() && (
              <MRT_TableHeadCellResizeHandle header={header} table={table} />
            )}
          </div>
        </div>
      )}
      {column.getCanFilter() && (
        <MRT_TableHeadCellFilterContainer header={header} table={table} />
      )}
    </td>
  );
};
