import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/24/solid";
import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";
import * as React from "react";
import Icon from "../../../../../Icons";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
  tableCellProps: any;
}

export const MRT_TableHeadCellSortLabel = <TData extends MRT_RowData>({
  header,
  table,
  tableCellProps,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { ArrowDownwardIcon },
      localization,
    },
  } = table;
  const { column } = header;
  const { columnDef } = column;
  const { isLoading, showSkeletons } = getState();

  const sortTooltip =
    isLoading || showSkeletons
      ? ""
      : column.getIsSorted()
      ? column.getIsSorted() === "desc"
        ? localization.sortedByColumnDesc.replace("{column}", columnDef.header)
        : localization.sortedByColumnAsc.replace("{column}", columnDef.header)
      : column.getNextSortingOrder() === "desc"
      ? localization.sortByColumnDesc.replace("{column}", columnDef.header)
      : localization.sortByColumnAsc.replace("{column}", columnDef.header);

  return (
    <div className="headless_tooltip_bottom" data-tip={sortTooltip}>
      <button
        aria-label={sortTooltip}
        {...rest}
        onClick={(e) => {
          // e.stopPropagation();
          // header.column.getToggleSortingHandler()?.(e);
        }}
        className={classNames(
          "headless_sorticon-btn",
          tableCellProps?.align !== "right" ? `headless_translate-x-05` : ""
        )}
      >
        {column.getIsSorted() === "desc" ? (
            <Icon name={"hk-sorting_arrow_down"}/>
          // <ArrowLongDownIcon className={"h-4 w-4"} />
        ) : (
            <Icon name={"hk-sorting_arrow_up"}/>
            // <ArrowLongUpIcon className={"h-4 w-4"} />
        )}
      </button>
    </div>
  );
};
