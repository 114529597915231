import React, {useState} from "react";
import {MRT_Cell, MRT_RowData, MRT_TableInstance} from "../../types";
import {parseFromValuesOrFunc} from "../../column.utils";

interface Props<TData extends MRT_RowData> {
    cell: MRT_Cell<TData>;
    table: MRT_TableInstance<TData>;
    children: React.ReactNode;
}

export const MRT_CopyButton = <TData extends MRT_RowData>({cell,table,children,...rest}: Props<TData>) => {
    const {
        options: {localization, muiCopyButtonProps},
    } = table;
    const {column, row} = cell;
    const {columnDef} = column;

    const [copied, setCopied] = useState(false);
    const handleCopy = (event: MouseEvent, text: unknown) => {
        event.stopPropagation();
        navigator.clipboard.writeText(text as string);
        setCopied(true);
        setTimeout(() => setCopied(false), 4000);
    };

    const buttonProps = {
        ...parseFromValuesOrFunc(muiCopyButtonProps, {
            cell,
            column,
            row,
            table,
        }),
        ...parseFromValuesOrFunc(columnDef.muiCopyButtonProps, {
            cell,
            column,
            row,
            table,
        }),
        ...rest,
    };

    return (
        <div className="headless_tooltip_bottom"
             data-tip={buttonProps?.title ??
                     (copied
                         ? localization.copiedToClipboard
                         : localization.clickToCopy)}
        >
            <button
                {...buttonProps}
                className={"headless_btn_transparent"}
                onClick={(e: MouseEvent) => handleCopy(e, cell.getValue())}
            >
                {children}
            </button>
        </div>
    );
};
