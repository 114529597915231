import React from "react";
import {
  MRT_RowData,
  MRT_TableInstance,
  MRT_TableOptions,
  Xor,
} from "./src/types";
import { useMaterialReactTable } from "./src/useMRTReactTable";
import { MRT_TablePaper } from "./src/components/table";
import "./tanstack-table.css";

type TableInstanceProp<TData extends MRT_RowData> = {
  table: MRT_TableInstance<TData>;
};

type Props<TData extends MRT_RowData> = Xor<
  TableInstanceProp<TData>,
  MRT_TableOptions<TData>
>;

const isTableInstanceProp = <TData extends MRT_RowData>(
  props: Props<TData>
): props is TableInstanceProp<TData> =>
  (props as TableInstanceProp<TData>).table !== undefined;

export const MaterialReactTable = <TData extends MRT_RowData>(
  props: Props<TData>
) => {
  let table: MRT_TableInstance<TData>;
  // @ts-ignore
  const a = useMaterialReactTable(props);
  if (isTableInstanceProp(props)) {
    table = props.table;
  } else {
    table = a;
  }

  return <MRT_TablePaper table={table} />;
};
