import React, { Component } from 'react';
import './global.css';

class Screen extends Component<any, any> {
  private SMinstance;
  //   public changeScreen: (viewName: string) => void;

  constructor(props: any) {
    super(props);
    this.SMinstance = props.instance;
    // this.changeScreen = this.SMinstance.changeScreen;
  }

  render() {
    return <div>View content goes here</div>;
  }
}

export default Screen;
