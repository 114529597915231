
export const buildYourOwnValue: { [key: string]: number } = {
    // "050": 0.5,
    "075": 0.75,
    "100": 1,
    "150": 1.5,
    "200": 2,
    "300": 3,
};

export const startWithSettingValue: { [key: string]: number } = {
    "075": 0.75,
    "100": 1,
    "150": 1.5,
    "200": 2,
    // "250": 2.5,
    "300": 3,
};
export const findNearestValue = (targetValue: number,isStartWithSetting?:boolean) => {
    let closestKey = "";
    let closestDifference = Infinity;
    const centerDiamondSizeValue = isStartWithSetting ? startWithSettingValue : buildYourOwnValue ;
// Iterate through each key in the object
    for (const key in centerDiamondSizeValue) {
        if (Object.prototype.hasOwnProperty.call(centerDiamondSizeValue, key)) {
            const value = centerDiamondSizeValue[key];
            const difference = Math.abs(value - targetValue);

            // Check if this difference is smaller than the current smallest difference
            if (difference < closestDifference) {
                closestDifference = difference;
                closestKey = key;
            }
        }
    }
    return closestKey;
}
