import {useState, useEffect, useRef} from "react";
import {MRT_ShowHideColumnsMenu} from "../menus/MRT_ShowHideColumnsMenu";
import * as React from "react";
import {MRT_RowData, MRT_TableInstance} from "../../types";

interface Props<TData extends MRT_RowData> {
    table: MRT_TableInstance<TData>;
    title?: string;
}

function listenForOutsideClicks(
    listening: boolean,
    setListening: (listening: boolean) => void,
    menuRef: React.RefObject<HTMLElement>,
    setIsOpen: (isOpen: boolean) => void,
): () => void {
    return () => {
        if (listening) return;
        if (!menuRef.current) return;
        setListening(true);
        ["click", "touchstart"].forEach((type) => {
            document.addEventListener(type, (evt) => {
                const cur = menuRef.current;
                const node = evt.target as Node;
                if (cur && cur.contains(node)) return;
                setIsOpen(false);
            });
        });
    };
}

export const MRT_ShowHideColumnsButton = <TData extends MRT_RowData>({table,title,...rest}: Props<TData>) => {
    const {
        options: {
            icons: {ViewColumnIcon},
            localization,
        },
    } = table;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = (): void => {
        setIsOpen(!isOpen);
    };

    const [listening, setListening] = useState<boolean>(false);
    useEffect(listenForOutsideClicks(listening, setListening, dropdownRef, setIsOpen));
    return (
        <>
            <div className="headless_showhide-column" ref={dropdownRef}>
                <div className="headless_tooltip_bottom"
                     data-tip={title ?? localization.showHideColumns}
                >
                    <button
                        aria-label={localization.expand}
                        onClick={toggleDropdown}
                        className={`headless_expandBtn-tooltipBtn`}
                    >
                        <ViewColumnIcon className={"h-7 w-7"}/>
                    </button>
                </div>
                {
                    isOpen && <div
                        className={`headless_showhidecolumn-menu`}
                        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                        <div className="headless_columnMenu-div" role="none">
                            <MRT_ShowHideColumnsMenu
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                                table={table}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    );
};
