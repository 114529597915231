import { TooltipDatatype} from "../../types";

export const TooltipDataFetch = async(setTooltipDataLoading :()=>{}, SetTooltipDataSuccess:(value:TooltipDatatype[])=>{}, SetTooltipDataError:(e:unknown)=>{})=>{
    setTooltipDataLoading();
    try {
        const response = await fetch(`${process.env.REACT_APP_TOOLTIP_API_KEY}`);
        if (!response.ok) {
            console.log(new Error(`HTTP error! Status: ${response.status}`));
        }
        const tooltips = await response.json();
        SetTooltipDataSuccess(tooltips);
    } catch (e) {
        SetTooltipDataError(e);
    }
}