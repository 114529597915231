import React from "react";
import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const MRT_TableHeadCellResizeHandle = <TData extends MRT_RowData>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: { columnResizeMode },
    setColumnSizingInfo,
  } = table;
  const { density } = getState();
  const { column } = header;

  return (
    <div
      className={classNames(
        `headless_thead-resizehandle`,
        density === "compact" ? `headless_mr-075` : `headless_mr-1`
      )}
      onDoubleClick={() => {
        setColumnSizingInfo((old) => ({
          ...old,
          isResizingColumn: false,
        }));
        column.resetSize();
      }}
      onMouseDown={header.getResizeHandler()}
      onTouchStart={header.getResizeHandler()}
      style={{
        transform:
          column.getIsResizing() && columnResizeMode === "onEnd"
            ? `translateX(${getState().columnSizingInfo.deltaOffset ?? 0}px)`
            : undefined,
      }}
    >
      <hr
        className={classNames(
          `headless_resizehandle-hr`,
          column.getIsResizing() ? "" : "headless_resizehandle-hr2"
        )}
      />
    </div>
  );
};
