// import { applyMiddleware, createStore } from 'redux';
// import { legacy_createStore as createStore, applyMiddleware } from 'redux';
// import RootReducer, {
//   RootAction,
//   RootStateInterface,
// } from './reducers/RootReducer';
// import { thunk } from 'redux-thunk';

// export type RootState = ReturnType<typeof RootReducer>;

// const middleware = applyMiddleware(thunk);

// const store = createStore<RootStateInterface, RootAction, {}, {}>(
//   RootReducer,
//   middleware,
// );
import { createStore } from 'redux';
import RootReducer from './reducers/RootReducer';

const store = createStore(RootReducer);

export default store;
