export const initialExclusionKey = (isPresetStyle: boolean) => {
  return isPresetStyle ?
    {
      'Ring_Style': [],
      'Diamond_Shape': [],
      'Center_Diamond_Size': [],
      'Ring_Crown': [],
      'Ring_Side': []
    } : {
      'Ring_Crown': [],
      'Ring_Side': [],
      'Diamond_Shape': [],
      'Center_Diamond_Size': [],
      'Ring_Style': []
    };
};
