import {MRT_RowData, MRT_TableInstance} from "../../types";
import * as React from "react";

interface Props<TData extends MRT_RowData> {
    table: MRT_TableInstance<TData>;
    title?: string;
}

export const MRT_ToggleGlobalFilterButton = <TData extends MRT_RowData>({table,title,...rest}: Props<TData>) => {
    const {
        getState,
        options: {
            icons: {SearchIcon, SearchOffIcon},

            localization,
        },
        refs: {searchInputRef},
        setShowGlobalFilter,
    } = table;
    const {globalFilter, showGlobalFilter} = getState();

    const handleToggleSearch = () => {
        setShowGlobalFilter(!showGlobalFilter);
        queueMicrotask(() => searchInputRef.current?.focus());
    };

    return (
        <div className="headless_tooltip_bottom"
             data-tip={title ?? localization.showHideSearch}
        >
            <button
                disabled={!!globalFilter}
                {...rest}
                className={"headless_expandBtn-tooltipBtn"}
                onClick={handleToggleSearch}
                aria-label={title ?? localization.showHideSearch}
            >
                {showGlobalFilter ? (
                    <SearchOffIcon className={"h-6 w-6"}/>
                ) : (
                    <SearchIcon className={"h-6 w-6"}/>
                )}
            </button>
        </div>
    );
};
