import React, {
  memo,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Memo_MRT_TableBodyCell, MRT_TableBodyCell } from "./MRT_TableBodyCell";
import { MRT_TableDetailPanel } from "./MRT_TableDetailPanel";
import { MRT_Cell, MRT_Row, MRT_RowData, MRT_TableInstance } from "../../types";
import { VirtualItem, Virtualizer } from "@tanstack/react-virtual";
import scrollIntoView from "scroll-into-view-if-needed";
import Styler from "stylefire";
import { animate } from "popmotion";

interface Props<TData extends MRT_RowData> {
  columnVirtualizer?: Virtualizer<HTMLDivElement, HTMLTableCellElement>;
  measureElement?: (element: HTMLTableRowElement) => void;
  numRows?: number;
  row: VirtualItem | MRT_Row<TData> | any;
  rowIndex: number;
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
  bodyRef?: MutableRefObject<undefined>;
  virtualRow?: VirtualItem | MRT_Row<TData> | undefined;
}

export const MRT_TableBodyRow = <TData extends MRT_RowData>({
  columnVirtualizer,
  measureElement,
  numRows,
  row,
  rowIndex,
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  virtualRow,
  bodyRef,
}: Props<TData>) => {
  const {
    getState,
    options: {
      enableRowOrdering,
      layoutMode,
      memoMode,
      muiTableBodyRowProps,
      renderDetailPanel,
    },
    setHoveredRow,
  } = table;

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const {
    expandRow,
    instanceData: { screenManager },
    setExpandedRow,
    isMobileView,
    activeTab,
    draggingColumn,
    draggingRow,
    editingCell,
    editingRow,
    hoveredRow,
    scrollToTop,
    toggleScrollTop,
    scrollTop,
    setScrollTop,
  } = getState();

  const tableRowProps =
    muiTableBodyRowProps instanceof Function
      ? muiTableBodyRowProps({ row, staticRowIndex: rowIndex, table })
      : muiTableBodyRowProps;

  const handleDragEnter = (_e: React.SyntheticEvent<EventTarget>) => {
    if (enableRowOrdering && draggingRow) {
      setHoveredRow(row);
    }
  };

  const rowRef = useRef<HTMLTableRowElement | null>(null);
  const handleToggleExpand = (event: MouseEvent): void => {
    event.stopPropagation();
    if (isMobileView && !(event as any).target.closest(".comparison")) {
      (screenManager as any).changeScreen({
        viewName: "details",
        id: row?.original.id,
      });
    } else if (+row.id === +row.index) {
      if (!(event as any).target.closest(".compare")) {
        setExpandedRow(activeTab, row?.original.id);
      }
      setScrollTop(false);
      toggleScrollTop(false);
    }
  };

  useEffect(() => {
    if (
      expandRow[activeTab] === row.original.id &&
      rowRef.current &&
      scrollToTop &&
      scrollTop
    ) {
      scrollIntoView(rowRef.current, {
        block: "start",
        // scrollMode:"always",
        // skipOverflowHiddenElements:true,
        // Your scroll actions will always be an array, even if there is nothing to scroll
        behavior: (instructions) => {
          const [{ el, top }] = instructions;
          const styler = Styler(instructions[0].el);
          animate({
            from: el.scrollTop,
            //@ts-ignore
            to: top - rowRef.current?.getBoundingClientRect().height - 60 + 4,
            // to: rowRef.current?.getBoundingClientRect().y - bodyRef.current?.getBoundingClientRect().y,
            type: "keyframes",
            onUpdate: (top) => styler.set("scrollTop", top),
          });
        },
        inline: "start",
      });
      // setIsExpandedRow(false);
    }
  }, [[expandRow[activeTab] === row.original.id], scrollToTop]);

  return (
    <>
      <tr
        data-index={virtualRow?.index}
        onDragEnter={handleDragEnter}
        onClick={handleToggleExpand}
        selected={row.getIsSelected()}
        ref={(node: HTMLTableRowElement | null) => {
          if (node) {
            rowRef.current = node;
            measureElement?.(node);
          }
        }}
        {...tableRowProps}
        className={classNames(
          `headless_tbody_tr`,
          tableRowProps?.hover !== false && row?.getIsSelected()
            ? `headless_tbody_tr_selected`
            : "",
          layoutMode === "grid" ? `headless_flex` : `headless_table-row`,
          draggingRow?.id === row.id || hoveredRow?.id === row.id
            ? `headless_opacity-50`
            : `headless_opacity-100`,
          virtualRow ? `headless_vertual_row` : `headless_transition-none`,
          row.original.isExpanded ? `headless_tbody_tr_expanded` : ``,
          +row.id !== +row.index ? `headless_tbody_grouping` : ``
        )}
        style={{
          transform: virtualRow
            ? `translateY(${"start" in virtualRow ? virtualRow?.start : 0}px)`
            : undefined,
          ...tableRowProps?.style,
        }}
      >
        {virtualPaddingLeft ? (
          <td style={{ display: "flex", width: virtualPaddingLeft }} />
        ) : null}
        {(virtualColumns ?? row.getVisibleCells()).map(
          (cellOrVirtualCell: MRT_Cell<TData> | VirtualItem) => {
            const cell = columnVirtualizer
              ? row.getVisibleCells()[(cellOrVirtualCell as VirtualItem).index]
              : (cellOrVirtualCell as MRT_Cell<TData>);
            const props = {
              cell,
              measureElement: columnVirtualizer?.measureElement,
              numRows,
              rowIndex,
              rowRef,
              table,
              virtualIndex: columnVirtualizer
                ? (cellOrVirtualCell as VirtualItem).index
                : undefined,
            };
            return cell ? (
              memoMode === "cells" &&
              cell.column.columnDef.columnDefType === "data" &&
              !draggingColumn &&
              !draggingRow &&
              editingCell?.id !== cell.id &&
              editingRow?.id !== row.id ? (
                <Memo_MRT_TableBodyCell key={cell.id} {...props} />
              ) : (
                <MRT_TableBodyCell key={cell.id} {...props} />
              )
            ) : null;
          }
        )}
        {virtualPaddingRight ? (
          <td style={{ display: "flex", width: virtualPaddingRight }} />
        ) : null}
      </tr>
      {!isMobileView &&
        renderDetailPanel &&
        expandRow[activeTab] === row.original.id &&
        +row.id === +row.index && (
          <MRT_TableDetailPanel
            parentRowRef={rowRef}
            row={row}
            rowIndex={rowIndex}
            table={table}
            isRowExpanded={expandRow[activeTab] === row.original.id}
            virtualRow={virtualRow}
          />
        )}
    </>
  );
};

export const Memo_MRT_TableBodyRow = memo(
  MRT_TableBodyRow,
  (prev, next) => prev.row === next.row && prev.rowIndex === next.rowIndex
) as typeof MRT_TableBodyRow;
