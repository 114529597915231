import React from "react";
import { MRT_TableFooterCell } from "./MRT_TableFooterCell";
import {
  MRT_Header,
  MRT_HeaderGroup,
  MRT_RowData,
  MRT_TableInstance,
} from "../../types";
import { VirtualItem } from "@tanstack/react-virtual";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  footerGroup: MRT_HeaderGroup<TData>;
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}
export const MRT_TableFooterRow = <TData extends MRT_RowData>({
  footerGroup,
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...rest
}: Props<TData>) => {
  const {
    options: { layoutMode, muiTableFooterRowProps },
  } = table;

  // if no content in row, skip row
  if (
    !footerGroup.headers?.some(
      (header) =>
        (typeof header.column.columnDef.footer === "string" &&
          !!header.column.columnDef.footer) ||
        header.column.columnDef.Footer
    )
  )
    return null;

  const tableRowProps =
    muiTableFooterRowProps instanceof Function
      ? muiTableFooterRowProps({ footerGroup, table })
      : muiTableFooterRowProps;

  return (
    <tr
      className={classNames(
        `headless_tfoot-row`,
        layoutMode === "grid" ? `headless_flex` : `headless_table-row`
      )}
      {...tableRowProps}
    >
      {virtualPaddingLeft ? (
        <th style={{ display: "flex", width: virtualPaddingLeft }} />
      ) : null}
      {(virtualColumns ?? footerGroup.headers).map((footerOrVirtualFooter) => {
        const footer = virtualColumns
          ? footerGroup.headers[footerOrVirtualFooter.index]
          : (footerOrVirtualFooter as MRT_Header<TData>);

        return footer ? (
          <MRT_TableFooterCell footer={footer} key={footer.id} table={table} />
        ) : null +'jjj';
      })}
      {virtualPaddingRight ? (
        <th style={{ display: "flex", width: virtualPaddingRight }} />
      ) : null}
    </tr>
  );
};
