import React from 'react';
import { PropsFromScreenManager } from '../../types';
import DiamondDetailsPage from './DiamondDetailsPage';
import Screen from '../../Screen';

class DiamondDetailsScreen extends Screen {
  constructor(props: PropsFromScreenManager) {
    super(props);
  }

  render() {
    return (
      <>
        <DiamondDetailsPage instanceData={this.props.instanceData} />
      </>
    );
  }
}

export default DiamondDetailsScreen;
