import StateManagerConstant from '../constant/StateManagerConstant';

// export const setStateManagerData =
//   (key: string, value: string) => async (dispatch: any) => {
//     dispatch({
//       type: StateManagerConstant.SET_PATH,
//       payload: { key, value },
//     });

//     return Promise.resolve();
//   };

export const setStateManagerData = (key: string, value: string | string[]) => ({
  type: StateManagerConstant.SET_PATH,
  payload: { key, value },
});

export const setStateEditData = (value: any) => ({
  type: StateManagerConstant.SET_EDIT_DATA,
  payload: value,
});
