import HeaderConstant from "../constant/HeaderConstant";

export const setHeaderTabDefault = () => ({
    type: HeaderConstant.SET_HEADER_TAB_TO_DEFAULT
})

export const setHeaderTabToSetting = (value:string) => ({
    type: HeaderConstant.SET_HEADER_TAB_TO_SETTING_OWN,
    payload:value
})

export const setDiamondTabIconTrue = (value:string) => ({
    type: HeaderConstant.SET_HEADER_TAB_DIAMOND_ICON,
    payload:value
})

export const setSettingTabIconTrue = () => ({
    type: HeaderConstant.SET_HEADER_TAB_SETTING_ICON
})

export const setInitialHeader = ()=>({
    type: HeaderConstant.SET_INITIAL_HEADER
})

export const setBothTabIconTrue = (value:string[]) =>({
    type:HeaderConstant.SET_BOTH_TAB_ICONE_TRUE,
    payload:value
})

export const setPreviousURLPath = (value:string) =>({
    type:HeaderConstant.SET_PREVIOUS_URL_PATH,
    payload:value
})

export const resetPath = (value:string)=>({
    type:HeaderConstant.SET_INITIAL_HEADER,
    payload:value
})

export const changeBackClick =(value:boolean)=>({
    type:HeaderConstant.CHANGE_BACK_CLICK,
    payload:value
})