import React from "react";
import { MRT_TablePagination } from "./MRT_TablePagination";
import { MRT_RowData, MRT_TableInstance } from "../../types";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}
export const MRT_BottomToolbar = <TData extends MRT_RowData>({
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: {
      enablePagination,
      muiBottomToolbarProps,
      positionPagination,
      renderBottomToolbarCustomActions,
    },
    refs: { bottomToolbarRef },
  } = table;
  const { isFullScreen } = getState();

  const toolbarProps =
    muiBottomToolbarProps instanceof Function
      ? muiBottomToolbarProps({ table })
      : muiBottomToolbarProps;

  return (
    <div
      {...toolbarProps}
      ref={(node) => {
        if (node) {
          bottomToolbarRef.current = node;
          if (toolbarProps?.ref) {
            // @ts-ignore
            toolbarProps.ref.current = node;
          }
        }
      }}
      className={classNames(
        `headless_bottom-toolbar`,
        isFullScreen ? `headless_bottom-fixed` : `headless_relative`
      )}
    >
      <div className={"headless_bottom-child"}>
        {renderBottomToolbarCustomActions ? (
          renderBottomToolbarCustomActions({ table })
        ) : (
          <span />
        )}
        <div className={classNames(`headless_bottom-toolbarAction`)}>
          {enablePagination &&
            ["bottom", "both"].includes(positionPagination ?? "") && (
              <MRT_TablePagination table={table} position="bottom" />
            )}
        </div>
      </div>
    </div>
  );
};
