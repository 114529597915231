import React, { FC } from "react";

interface MenuItemProps {
  children: React.ReactNode;
  disabled?: boolean;
  divider?: boolean;
  hidden?: boolean;
  value?: string;
  className?: string;
}

const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};

export const MRT_MenuItem: FC<MenuItemProps> = (props) => {
  const { children, divider, hidden, value, className, ...rest } = props;
  return (
    <>
      <li
        value={value}
        {...rest}
        className={classNames(
          "headless_dropdown-menu",
          divider ? "headless_dropdownMenu-divider" : "",
          hidden || props.disabled ? "headless_opacity-40" : "",
          className as string
        )}
      >
        {children}
      </li>
    </>
  );
};
