import React, { FC } from "react";
import classes from "./Tab.module.css";
import Icon from "../../Icons";
import { TabProps } from "../../../types";
import Tooltip from "../../Tooltip";

const Tab: FC<TabProps> = ({
  label,
  onClick,
  isActive,
  isIcon,
  iconName,
  isNotShow,
}) => {
  return (
    <>
      {!isNotShow ? (
        <div
          onClick={onClick}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              onClick();
            }
          }}
          tabIndex={!isActive ? 0 : -1}
          className={`${classes.tab} ${isActive ? classes.active : ""} ${
            isNotShow ? classes.none : classes.show
          }`}
        >
          {isIcon && <Icon name={iconName} />}
          {label.includes("Recommended") ? (
            <span className={"inline-flex align-center"}>
              {label} <Tooltip title={label.split(" ")[0]} />
            </span>
          ) : (
            label
          )}
        </div>
      ) : null}
    </>
  );
};

export default Tab;
