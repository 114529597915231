import * as React from "react";
import { useState } from "react";
import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";
import { CollectionChildren } from "@react-types/shared";

export const commonMenuItemStyles = {
  py: "6px",
  my: 0,
  fontWeight: 400,
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  gap: "16px",
};

export const commonListItemStyles = {
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: 400,
  py: "6px",
  gap: "16px",
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props<TData extends MRT_RowData> {
  anchorEl: HTMLElement | null;
  header: MRT_Header<TData>;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  table: MRT_TableInstance<TData>;
  title?: string;
  setIsOpen: (isOpen: boolean) => void;
}

export const MRT_ColumnActionMenu = <TData extends MRT_RowData>({
  anchorEl,
  header,
  setAnchorEl,
  table,
  title,
  setIsOpen,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    toggleAllColumnsVisible,
    setColumnOrder,
    options: {
      columnFilterModeOptions,
      enableColumnFilterModes,
      enableColumnFilters,
      enableColumnResizing,
      enableGrouping,
      enableHiding,
      enablePinning,
      enableSorting,
      enableSortingRemoval,
      icons: {
        ArrowRightIcon,
        ClearAllIcon,
        ViewColumnIcon,
        DynamicFeedIcon,
        FilterListIcon,
        FilterListOffIcon,
        PushPinIcon,
        SortIcon,
        RestartAltIcon,
        VisibilityOffIcon,
      },
      localization,
      renderColumnActionsMenuItems,
    },
    refs: { filterInputRefs },
    setColumnSizingInfo,
    setShowColumnFilters,
  } = table;
  const { column } = header;
  const { columnDef } = column;
  const { columnSizing, columnVisibility, density, showColumnFilters } =
    getState();
  const columnFilterValue = column.getFilterValue();

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] =
    useState<HTMLElement | null>(null);

  const handleClearSort = () => {
    column.clearSorting();
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleSortAsc = () => {
    column.toggleSorting(false);
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleSortDesc = () => {
    column.toggleSorting(true);
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleResetColumnSize = () => {
    setColumnSizingInfo((old) => ({ ...old, isResizingColumn: false }));
    column.resetSize();
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleHideColumn = () => {
    column.toggleVisibility(false);
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handlePinColumn = (pinDirection: "left" | "right" | false) => {
    column.pin(pinDirection);
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleGroupByColumn = () => {
    column.toggleGrouping();
    setColumnOrder((old) => ["mrt-row-expand", ...old]);
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleClearFilter = () => {
    column.setFilterValue("");
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleFilterByColumn = () => {
    setShowColumnFilters(true);
    queueMicrotask(() => filterInputRefs.current[`${column.id}-0`]?.focus());
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleShowAllColumns = () => {
    toggleAllColumnsVisible(true);
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleOpenFilterModeMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setFilterMenuAnchorEl(event.currentTarget);
    setIsOpen(false);
  };
  const isSelectFilter = !!columnDef.filterSelectOptions;

  const allowedColumnFilterOptions =
    columnDef?.columnFilterModeOptions ?? columnFilterModeOptions;

  const showFilterModeSubMenu =
    enableColumnFilterModes &&
    columnDef.enableColumnFilterModes !== false &&
    !isSelectFilter &&
    (allowedColumnFilterOptions === undefined ||
      !!allowedColumnFilterOptions?.length);

  const internalColumnMenuItems = [
    ...(enableSorting && column.getCanSort()
      ? [
          enableSortingRemoval !== false && (
            <button
              disabled={!column.getIsSorted()}
              key={0}
              className={classNames(
                !column.getIsSorted() ? "" : "headless_cursor-pointer",
                "headless_columnAction-btn"
              )}
              onClick={handleClearSort}
              style={commonMenuItemStyles}
            >
              <div style={commonListItemStyles}>
                <ClearAllIcon className={"h-6 w-6"} />

                {localization.clearSort}
              </div>
            </button>
          ),
          <button
            disabled={column.getIsSorted() === "asc"}
            key={1}
            className={classNames(
              "headless_columnAction-btn2",
              column.getIsSorted() === "asc" ? `` : `headless_Action-columnBtn`
            )}
            onClick={handleSortAsc}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <SortIcon
                className={"h-6 w-6"}
                style={{ transform: "rotate(180deg) scaleX(-1)" }}
              />
              <p>
                {localization.sortByColumnAsc?.replace(
                  "{column}",
                  String(columnDef.header)
                )}
              </p>
            </div>
          </button>,
          <button
            key={2}
            className={classNames(
              enableColumnFilters || enableGrouping || enableHiding
                ? "headless_columnAction-btn3"
                : "",
              column.getIsSorted() === "desc" ? `` : `headless_cursor-pointer`,
              "headless_grouping-btn"
            )}
            disabled={column.getIsSorted() === "desc"}
            onClick={handleSortDesc}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <SortIcon className={"h-6 w-6"} />
              {localization.sortByColumnDesc?.replace(
                "{column}",
                String(columnDef.header)
              )}
            </div>
          </button>,
        ]
      : []),
    ...(enableColumnFilters && column.getCanFilter()
      ? [
          <button
            disabled={
              !columnFilterValue ||
              (Array.isArray(columnFilterValue) &&
                !columnFilterValue.filter((value) => value).length)
            }
            key={3}
            className={classNames(
              !columnFilterValue ||
                (Array.isArray(columnFilterValue) &&
                  !columnFilterValue.filter((value) => value).length)
                ? ""
                : "headless_Action-columnBtn",
              "headless_columnAction-btn2 "
            )}
            onClick={handleClearFilter}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <FilterListOffIcon className={"h-6 w-6"} />
              {localization.clearFilter}
            </div>
          </button>,
          <button
            className={classNames(
              showColumnFilters && !enableColumnFilterModes
                ? ""
                : "headless_Action-columnBtn",
              enableGrouping || enableHiding
                ? "headless_columnAction-btn3"
                : "",
              "headless_action-grouping"
            )}
            disabled={showColumnFilters && !enableColumnFilterModes}
            key={4}
            onClick={
              showColumnFilters
                ? handleOpenFilterModeMenu
                : handleFilterByColumn
            }
            style={commonMenuItemStyles}
          >
            <div>
              <div style={commonListItemStyles}>
                <FilterListIcon className={"h-6 w-6"} />
                {localization.filterByColumn?.replace(
                  "{column}",
                  String(columnDef.header)
                )}
              </div>
              {showFilterModeSubMenu && (
                <button
                  onClick={handleOpenFilterModeMenu}
                  onMouseEnter={handleOpenFilterModeMenu}
                >
                  <ArrowRightIcon className={"h-6 w-6"} />
                </button>
              )}
            </div>
          </button>,
        ]
      : []),
    ...(enableGrouping && column.getCanGroup()
      ? [
          <button
            key={6}
            className={classNames(
              enablePinning ? "headless_grouping-btn4" : "",
              "headless_actionMenu-columnGrp"
            )}
            onClick={handleGroupByColumn}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <DynamicFeedIcon className={"h-6 w-6"} />

              {localization[
                column.getIsGrouped() ? "ungroupByColumn" : "groupByColumn"
              ]?.replace("{column}", String(columnDef.header))}
            </div>
          </button>,
        ]
      : []),
    ...(enablePinning && column.getCanPin()
      ? [
          <button
            disabled={column.getIsPinned() === "left" || !column.getCanPin()}
            key={7}
            className={classNames(
              column.getIsPinned() === "left" || !column.getCanPin()
                ? ""
                : "headless_Action-columnBtn",
              "headless_columnAction-btn2"
            )}
            onClick={() => handlePinColumn("left")}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <PushPinIcon
                className={"h-6 w-6"}
                style={{ transform: "rotate(90deg)" }}
              />
              {localization.pinToLeft}
            </div>
          </button>,
          <button
            disabled={column.getIsPinned() === "right" || !column.getCanPin()}
            key={8}
            className={classNames(
              column.getIsPinned() === "right" || !column.getCanPin()
                ? ""
                : "headless_Action-columnBtn",
              "headless_columnAction-btn2 "
            )}
            onClick={() => handlePinColumn("right")}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <PushPinIcon
                className={"h-6 w-6"}
                style={{ transform: "rotate(-90deg)" }}
              />
              {localization.pinToRight}
            </div>
          </button>,
          <button
            disabled={!column.getIsPinned()}
            key={9}
            className={classNames(
              !column.getIsPinned() ? "" : "headless_Action-columnBtn",
              enableHiding ? "headless_columnAction-btn3" : "",
              "headless_action-grouping"
            )}
            onClick={() => handlePinColumn(false)}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <PushPinIcon className={"h-6 w-6"} />
              {localization.unpin}
            </div>
          </button>,
        ]
      : []),
    ...(enableColumnResizing && column.getCanResize()
      ? [
          <button
            disabled={!columnSizing[column.id]}
            key={10}
            className={classNames(
              !columnSizing[column.id] ? "" : "headless_Action-columnBtn",
              "headless_columnAction-btn2"
            )}
            onClick={handleResetColumnSize}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <RestartAltIcon className={"h-6 w-6"} />
              {localization.resetColumnSize}
            </div>
          </button>,
        ]
      : []),
    ...(enableHiding
      ? [
          <button
            disabled={!column.getCanHide()}
            key={11}
            className={classNames(
              !column.getCanHide() ? "" : "headless_Action-columnBtn",
              "headless_columnAction-btn2"
            )}
            onClick={handleHideColumn}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <VisibilityOffIcon className={"h-6 w-6"} />

              {localization.hideColumn?.replace(
                "{column}",
                String(columnDef.header)
              )}
            </div>
          </button>,
          <button
            disabled={
              !Object.values(columnVisibility).filter((visible) => !visible)
                .length
            }
            key={12}
            className={classNames(
              !Object.values(columnVisibility).filter((visible) => !visible)
                .length
                ? ""
                : "headless_Action-columnBtn",
              "headless_columnAction-btn2"
            )}
            onClick={handleShowAllColumns}
            style={commonMenuItemStyles}
          >
            <div style={commonListItemStyles}>
              <ViewColumnIcon className={"h-6 w-6"} />

              {localization.showAllColumns?.replace(
                "{column}",
                String(columnDef.header)
              )}
            </div>
          </button>,
        ]
      : []),
  ].filter(Boolean);
  const columnActionsMenuItems: CollectionChildren<object> =
    ((columnDef.renderColumnActionsMenuItems?.({
      closeMenu: () => setAnchorEl(null),
      column,
      internalColumnMenuItems,
      table,
    }) ??
      renderColumnActionsMenuItems?.({
        closeMenu: () => setAnchorEl(null),
        column,
        internalColumnMenuItems,
        table,
      }) ??
      internalColumnMenuItems) as CollectionChildren<object>) || [];

  return <>{columnActionsMenuItems}</>;
};
