import React from "react";
import { MRT_TableFooterRow } from "./MRT_TableFooterRow";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { VirtualItem } from "@tanstack/react-virtual";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}
export const MRT_TableFooter = <TData extends MRT_RowData>({
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...rest
}: Props<TData>) => {
  const {
    getFooterGroups,
    getState,
    options: { enableStickyFooter, layoutMode, muiTableFooterProps },
  } = table;
  const { isFullScreen } = getState();

  const tableFooterProps =
    muiTableFooterProps instanceof Function
      ? muiTableFooterProps({ table })
      : muiTableFooterProps;

  const stickFooter =
    (isFullScreen || enableStickyFooter) && enableStickyFooter !== false;

  return (
    <tfoot
      {...tableFooterProps}
      className={classNames(
        stickFooter
          ? `headless_tfoot`
          : `headless_border-foreground`,
        layoutMode === "grid" ? `headless_grid` : `headless_table-row-group`,
        `headless_border-b-solid`
      )}
    >
      {getFooterGroups().map((footerGroup) => (
        <MRT_TableFooterRow
          footerGroup={footerGroup as any}
          key={footerGroup.id}
          table={table}
          virtualColumns={virtualColumns}
          virtualPaddingLeft={virtualPaddingLeft}
          virtualPaddingRight={virtualPaddingRight}
        />
      ))}
    </tfoot>
  );
};
