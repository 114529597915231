import React, {FC, useCallback, useEffect} from 'react';
import ScreenManager from './ScreenManager';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'rc-slider/assets/index.css';
import './App.css';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers/RootReducer';

// Define the type of props expected by the component
interface Props {
  footer: {
    stickyHeight: number;
  };
}

// Map the Redux state to component props
const mapStateToProps = (state: RootState): Props => ({
  footer: state.footer,
});

// Connect the component with Redux and define the connected props
const connector = connect(mapStateToProps);

// Define the props type from Redux connector
type PropsFromRedux = ConnectedProps<typeof connector>;

// Define the App component
declare global{
  interface Window {
    _env_: {
      REACT_APP_3D_FRAME_URL: string | undefined;
    };
  }
}
const App: FC<PropsFromRedux> = ({ footer }) => {
  useEffect(() => {
    (function () {
      window._env_ = {
        REACT_APP_3D_FRAME_URL: process.env.REACT_APP_3D_FRAME_URL
      };
    })();
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/3dFrame-integration.js`;
    script.async = true;
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    const handleTabPress = (event:any) => {
      (event.keyCode === 9) && document.body.classList.add('tabbing');
      if ((event.metaKey || event.ctrlKey) && (event.key === 'Delete' || event.key === 'Backspace')) {
        window.history.go(-1);
      }
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        const nextElement = event.target.nextElementSibling;
        if (nextElement && !event.target.closest('.labelRoot') && !event.target.closest('#listing_inner_grid')) {
          nextElement.focus();
        } else if (!nextElement && !event.target.closest('.labelRoot') && !event.target.closest('#listing_inner_grid')) {
          const parent = event.target.parentElement;
          const firstChild = parent?.firstElementChild;
          if (firstChild) {
            firstChild.focus();
          }
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        const previousElement = event.target.previousElementSibling;
        if (previousElement && !event.target.closest('.labelRoot') && !event.target.closest('#listing_inner_grid')) {
          previousElement.focus();
        } else if (!previousElement && !event.target.closest('.labelRoot') && !event.target.closest('#listing_inner_grid')) {
          const parent = event.target.parentElement;
          const lastChild = parent?.lastElementChild;
          if (lastChild) {
            lastChild.focus();
          }
        }
      }
    };
    /*const firstTabbableElement = document.querySelector('[tabindex="0"], button') as HTMLInputElement | HTMLElement;
    if (firstTabbableElement) {
      firstTabbableElement.focus();
    }*/
    const handleMouseDown = () => {
      document.body.classList.remove('tabbing');
    };
    document.addEventListener('keydown', handleTabPress);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('keydown', handleTabPress);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  return (
    <div className="App" id={'rootApp'} style={{ '--stickyHeight': `${footer.stickyHeight}px` } as React.CSSProperties}>
      <ScreenManager data={{ app: App }} />
    </div>
  );
};

// Connect the App component with Redux
export default connector(App);

/*
import React, {FC} from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import CMSPage from "./components/CMSPage/CMSPage";
import Diamond from "./components/Diamond/Diamond";
import Header from "./components/Header/Header";
import DiamondDetailsPage from "./components/DiamondDetailsPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "rc-slider/assets/index.css";
import  "./global.css";
import StartWithSetting from "./components/StartWithSetting";
import StickyFooter from "./components/StickyFooter";
import BuildOwn from "./components/BuildOwn";
import {RootState} from "./reducers/RootReducer";
import {connect, ConnectedProps} from "react-redux";

const mapStateToProps = (state: RootState) => ({
    footer: state.footer
});
const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromAppRedux = ConnectedProps<typeof connector>;

const App :FC<PropsFromAppRedux>=({footer})=> {
  return (
    <div className="App" id={'rootApp'} style={{'--stickyHeight':`${footer.stickyHeight}px`} as {[key:string]:string}}>
        <Routes>
          <Route path="/" element={ <CMSPage/> } />
          <Route path="/select-diamond" element={ <><Header /> <Diamond/><StickyFooter/></> } />
          <Route path="/diamond-details/:id" element={ <><Header /><DiamondDetailsPage/><StickyFooter/></> } />
          <Route path="/build-own" element={ <><Header /><BuildOwn /> <StickyFooter/></> } />
          <Route path="/ring-builder" element={ <><Header /><BuildOwn /> <StickyFooter/></> } />
          <Route path="/start-with-setting" element={ <><Header /><StartWithSetting/><StickyFooter/></> } />
      </Routes>
    </div>
  );
}

export default connector(App);
*/
