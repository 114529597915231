// StoreScreen.tsx
import React from "react";
import StorePage from "./StorePage";
import { PropsFromScreenManager } from "../../types";
import Screen from "../../Screen";

class StoreScreen extends Screen {
  constructor(props: PropsFromScreenManager) {
    super(props);
  }

  render() {
    return <StorePage instanceData={this.props.instanceData} />;
  }
}

export default StoreScreen;
