import React from "react";
import { MRT_TableHeadRow } from "./MRT_TableHeadRow";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { VirtualItem } from "@tanstack/react-virtual";
import { parseFromValuesOrFunc } from "../../column.utils";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}
export const MRT_TableHead = <TData extends MRT_RowData>({
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...rest
}: Props<TData>) => {
  const {
    getHeaderGroups,
    getState,
    options: { enableStickyHeader, layoutMode, muiTableHeadProps },
  } = table;
  const { isFullScreen } = getState();

  const tableHeadProps = {
    ...parseFromValuesOrFunc(muiTableHeadProps, { table }),
    ...rest,
  };

  const stickyHeader = enableStickyHeader || isFullScreen;

  return (
    <thead
      className={classNames(
        `headless_thead`,
        layoutMode === "grid" ? `headless_grid` : `headless_table-row-group`,
        stickyHeader ? `headless_sticky-z-2` : `headless_relative`,
        stickyHeader && layoutMode === "grid" ? `headless_top-0` : ``
      )}
      {...tableHeadProps}
    >
      {getHeaderGroups().map((headerGroup) => (
        <MRT_TableHeadRow
          headerGroup={headerGroup}
          key={headerGroup.id}
          table={table}
          virtualColumns={virtualColumns}
          virtualPaddingLeft={virtualPaddingLeft}
          virtualPaddingRight={virtualPaddingRight}
        />
      ))}
    </thead>
  );
};
