import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { parseFromValuesOrFunc } from "../../column.utils";
import debounce  from "debounce";
import * as React from "react";

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}

export const MRT_GlobalFilterTextField = <TData extends MRT_RowData>({
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    setGlobalFilter,
    options: {
      enableGlobalFilterModes,
      icons: { SearchIcon, CloseIcon },
      localization,
      manualFiltering,
      muiSearchTextFieldProps,
    },
    refs: { searchInputRef },
  } = table;
  const { globalFilter, showGlobalFilter } = getState();

  const textFieldProps = {
    ...parseFromValuesOrFunc(muiSearchTextFieldProps, {
      table,
    }),
    ...rest,
  };

  const isMounted = useRef(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState(globalFilter ?? "");

  const handleChangeDebounced = useCallback(
    debounce(
      (event: ChangeEvent<HTMLInputElement>) => {
        setGlobalFilter(event.target.value ?? undefined);
      },
      manualFiltering ? 500 : 250
    ),
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    handleChangeDebounced(event);
  };

  const handleGlobalFilterMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClear = () => {
    setSearchValue("");
    setGlobalFilter(undefined);
  };

  useEffect(() => {
    if (isMounted.current) {
      if (globalFilter === undefined) {
        handleClear();
      } else {
        setSearchValue(globalFilter);
      }
    }
    isMounted.current = true;
  }, [globalFilter]);
  return (
    <>
      {" "}
      {showGlobalFilter ? (
        <div className="headless_globalFilter">
          {
            enableGlobalFilterModes ? (
                  <div className="headless_tooltip_bottom"
                       data-tip={localization.changeSearchMode}
                  >
                    <button
                        className={"headless_expandBtn-tooltipBtn"}
                        aria-label={localization.changeSearchMode}
                        onClick={handleGlobalFilterMenuOpen}
                    >
                      <SearchIcon className={"h-6 w-6"} />
                    </button>
                  </div>
            ) : (
                <SearchIcon
                    style={{ marginRight: "4px" }}
                    className={"h-6 w-6"}
                />
            )
          }
          <input
            type="text"
            placeholder={localization.search}
            value={searchValue ?? ""}
            onChange={handleChange}
            className="headless_globalFilter-input"
            ref={(inputRef: HTMLInputElement) => {
              searchInputRef.current = inputRef;
              if (textFieldProps?.inputRef) {
                textFieldProps.inputRef = inputRef;
              }
            }}
            {...textFieldProps}
          />
          <div className="headless_tooltip_bottom"
               data-tip={localization.clearSearch ?? ""}
          >
            <button
                {...rest}
                className={"headless_expandBtn-tooltipBtn"}
                aria-label={localization.clearSearch}
                disabled={!searchValue?.length}
                onClick={handleClear}
            >
              <CloseIcon className={"h-5 w-5"} />
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};
