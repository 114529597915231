import {MRT_RowData, MRT_TableInstance} from "../../types";
import * as React from "react";

interface Props<TData extends MRT_RowData> {
    table: MRT_TableInstance<TData>;
    title?: string;
}

export const MRT_ToggleFullScreenButton = <TData extends MRT_RowData>({table,title,...rest}: Props<TData>) => {
    const {
        getState,
        options: {
            icons: {FullscreenExitIcon, FullscreenIcon},
            localization,
        },
        setIsFullScreen,
    } = table;
    const {isFullScreen} = getState();

    const handleToggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    return (
        <div className="headless_tooltip_bottom"
             data-tip={title ?? localization.toggleFullScreen}
        >
            <button
                className={"headless_expandBtn-tooltipBtn"}
                {...rest}
                onClick={handleToggleFullScreen}
                aria-label={localization.showHideFilters}
            >
                {isFullScreen ? (
                    <FullscreenExitIcon className={"h-6 w-6"}/>
                ) : (
                    <FullscreenIcon className={"h-6 w-6"}/>
                )}
            </button>
        </div>
    );
};
