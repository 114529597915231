import TooltipConstant from "../constant/TooltipConstant";
import {TooltipDatatype} from "../types";

export const setTooltipDataLoading = () => ({
    type: TooltipConstant.SET_TOOLTIP_DATA_LOADING
})

export const SetTooltipDataSuccess = (tooltip: TooltipDatatype[]) => ({
    type: TooltipConstant.SET_TOOLTIP_DATA_SUCCESS,
    payload: tooltip
})

export const SetTooltipDataError = (error: unknown) => ({
    type: TooltipConstant.SET_TOOLTIP_DATA_ERROR,
    payload: error
})

export const SetInitialTooltip =()=>({
    type:TooltipConstant.SET_INITIAL_TOOLTIP
})