import React from "react";
import { MRT_Row, MRT_RowData, MRT_TableInstance } from "../../types";
import { RefObject, DragEvent } from "react";
import { MRT_GrabHandleButton } from "../buttons/MRT_GrabHandleButton";

interface Props<TData extends MRT_RowData> {
  row: MRT_Row<TData>;
  rowRef: RefObject<HTMLTableRowElement>;
  table: MRT_TableInstance<TData>;
}

export const MRT_TableBodyRowGrabHandle = <TData extends MRT_RowData>({
  row,
  rowRef,
  table,
  ...rest
}: Props<TData>) => {
  const {
    options: { muiRowDragHandleProps },
  } = table;

  const iconButtonProps =
    muiRowDragHandleProps instanceof Function
      ? muiRowDragHandleProps({ row, table })
      : muiRowDragHandleProps;

  const handleDragStart = (event: DragEvent) => {
    iconButtonProps?.onDragStart?.(event);
    event.dataTransfer.setDragImage(rowRef.current as HTMLElement, 0, 0);
    table.setDraggingRow(row as any);
  };

  const handleDragEnd = (event: DragEvent) => {
    iconButtonProps?.onDragEnd?.(event);
    table.setDraggingRow(null);
    table.setHoveredRow(null);
  };

  return (
    <MRT_GrabHandleButton
      iconButtonProps={iconButtonProps}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      table={table}
    />
  );
};
