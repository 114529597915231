import { ChevronDownIcon as ArrowDownwardIcon } from "@heroicons/react/24/solid";
import { ChevronRightIcon as ArrowRightIcon } from "@heroicons/react/24/solid";
import { XMarkIcon as CancelIcon } from "@heroicons/react/24/solid";
import { BackspaceIcon as ClearAllIcon } from "@heroicons/react/24/solid";
import { XMarkIcon as CloseIcon } from "@heroicons/react/24/solid";
import { Bars2Icon as DensityLargeIcon } from "@heroicons/react/24/solid";
import { Bars3Icon as DensityMediumIcon } from "@heroicons/react/24/solid";
import { Bars4Icon as DensitySmallIcon } from "@heroicons/react/24/solid";
import { Bars2Icon as DragHandleIcon } from "@heroicons/react/24/solid";
import { FolderIcon as DynamicFeedIcon } from "@heroicons/react/24/solid";
import { PencilIcon as EditIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon as ExpandMoreIcon } from "@heroicons/react/24/solid";
import { FunnelIcon as FilterAltIcon } from "@heroicons/react/24/solid";
import { Bars3BottomLeftIcon as FilterListIcon } from "@heroicons/react/24/solid";
import { BarsArrowDownIcon as FilterListOffIcon } from "@heroicons/react/24/solid";
import { ArrowsPointingInIcon as FullscreenExitIcon } from "@heroicons/react/24/solid";
import { ArrowsPointingOutIcon as FullscreenIcon } from "@heroicons/react/24/solid";
import { ChevronDoubleDownIcon as KeyboardDoubleArrowDownIcon } from "@heroicons/react/24/solid";
import { EllipsisHorizontalIcon as MoreHorizIcon } from "@heroicons/react/24/solid";
import { EllipsisVerticalIcon as MoreVertIcon } from "@heroicons/react/24/solid";
import { ArrowDownCircleIcon as PushPinIcon } from "@heroicons/react/24/solid";
import { ArrowPathIcon as RestartAltIcon } from "@heroicons/react/24/solid";
import { InboxStackIcon as SaveIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon as SearchIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassMinusIcon as SearchOffIcon } from "@heroicons/react/24/solid";
import { AdjustmentsHorizontalIcon as SortIcon } from "@heroicons/react/24/solid";
import { ViewColumnsIcon as ViewColumnIcon } from "@heroicons/react/24/solid";
import { EyeSlashIcon as VisibilityOffIcon } from "@heroicons/react/24/solid";

export interface MRT_Icons {
  ArrowDownwardIcon: any;
  ArrowRightIcon: any;
  CancelIcon: any;
  ClearAllIcon: any;
  CloseIcon: any;
  DensityLargeIcon: any;
  DensityMediumIcon: any;
  DensitySmallIcon: any;
  DragHandleIcon: any;
  DynamicFeedIcon: any;
  EditIcon: any;
  ExpandMoreIcon: any;
  FilterAltIcon: any;
  FilterListIcon: any;
  FilterListOffIcon: any;
  FullscreenExitIcon: any;
  FullscreenIcon: any;
  KeyboardDoubleArrowDownIcon: any;
  MoreHorizIcon: any;
  MoreVertIcon: any;
  PushPinIcon: any;
  RestartAltIcon: any;
  SaveIcon: any;
  SearchIcon: any;
  SearchOffIcon: any;
  SortIcon: any;
  ViewColumnIcon: any;
  VisibilityOffIcon: any;
}

export const MRT_Default_Icons: MRT_Icons = {
  ArrowDownwardIcon,
  ArrowRightIcon,
  CancelIcon,
  ClearAllIcon,
  CloseIcon,
  DensityLargeIcon,
  DensityMediumIcon,
  DensitySmallIcon,
  DragHandleIcon,
  DynamicFeedIcon,
  EditIcon,
  ExpandMoreIcon,
  FilterAltIcon,
  FilterListIcon,
  FilterListOffIcon,
  FullscreenExitIcon,
  FullscreenIcon,
  KeyboardDoubleArrowDownIcon,
  MoreHorizIcon,
  MoreVertIcon,
  PushPinIcon,
  RestartAltIcon,
  SaveIcon,
  SearchIcon,
  SearchOffIcon,
  SortIcon,
  ViewColumnIcon,
  VisibilityOffIcon,
};
