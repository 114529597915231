import {MRT_RowData, MRT_TableInstance} from "../../types";
import React, {DragEventHandler} from "react";

interface Props<TData extends MRT_RowData> {
    iconButtonProps?: any;
    location?: "column" | "row";
    onDragEnd: DragEventHandler<HTMLButtonElement>;
    onDragStart: DragEventHandler<HTMLButtonElement>;
    table: MRT_TableInstance<TData>;
}

export const MRT_GrabHandleButton = <TData extends MRT_RowData>({
        iconButtonProps,
        location,
        onDragEnd,
        onDragStart,
        table,
        ...rest
    }: Props<TData>) => {
    const {
        options: {
            icons: {DragHandleIcon},
            localization,
        },
    } = table;

    return (
        <div className="headless_tooltip_bottom"
             data-tip={iconButtonProps?.title ?? localization.move}
        >
            <button
                draggable="true"
                {...iconButtonProps}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    iconButtonProps?.onClick?.(e);
                }}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                className={
                    "headless_grabhandle-btn"
                }
            >
                <DragHandleIcon className={"h-6 w-6"}/>
            </button>
        </div>
    );
};
