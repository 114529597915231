export const getRingPrice = async(setPriceLoading:any,setRingPrice:any,setRingPriceError:any,data : {[key:string]:string | number}) => {
  setPriceLoading();
  try {
    const ringPriceUrl = process.env.REACT_APP_RING_PRICE;
    const queryParams = new URLSearchParams(data as Record<string, string>).toString();
      const response = await fetch(`${ringPriceUrl}?${queryParams}`);
      if (!response.ok) {
        setRingPriceError(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();
      setRingPrice(res);
  }catch (error) {
    setRingPriceError(error);
  }
}
