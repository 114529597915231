import FooterConstant from "../constant/FooterConstant";

export const setFooterToggle = () => ({
    type: FooterConstant.SET_FOOTER_TOGGLE
});
export const setIsShowFooter = (value:boolean) => ({
    type: FooterConstant.IS_SHOW_FOOTER,
    payload: value
});

export const setFooterHeight =(height:number)=>({
    type:FooterConstant.SET_FOOTER_HEIGHT,
    payload:height
})

export const setStyleID =(styleID:string)=>({
    type:FooterConstant.SET_STYLE_ID,
    payload:styleID
})

export const setInitialFooter = () => ({
    type: FooterConstant.SET_INITIAL_FOOTER
});

export const setRedirectEditPage = (value:boolean) => ({
    type: FooterConstant.SET_REDIRECT_EDIT_PAGE,
    payload:value
});

export const setIsShowCartPage = () => ({
    type: FooterConstant.SET_IS_SHOW_CART_PAGE
});
