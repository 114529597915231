import React, { useEffect, useLayoutEffect, useState } from "react";
import { parseFromValuesOrFunc } from "../../column.utils";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { MRT_Table } from "./MRT_Table";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export const MRT_TableContainer = <TData extends MRT_RowData>({
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: { enableStickyHeader, muiTableContainerProps },
    refs: { bottomToolbarRef, tableContainerRef, topToolbarRef },
  } = table;
  const { isFullScreen } = getState();

  const [totalToolbarHeight, setTotalToolbarHeight] = useState(0);

  const tableContainerProps = {
    ...parseFromValuesOrFunc(muiTableContainerProps, {
      table,
    }),
    ...rest,
  };

  useIsomorphicLayoutEffect(() => {
    const topToolbarHeight =
      typeof document !== "undefined"
        ? topToolbarRef.current?.offsetHeight ?? 0
        : 0;

    const bottomToolbarHeight =
      typeof document !== "undefined"
        ? bottomToolbarRef?.current?.offsetHeight ?? 0
        : 0;

    setTotalToolbarHeight(topToolbarHeight + bottomToolbarHeight);
  });

  return (
    <div
      className={classNames(
        `headless_table-container`,
        // enableStickyHeader ? `max-h-[calc(350px_+_calc(100vh_-_${totalToolbarHeight}px))]` : ``
        enableStickyHeader ? `headless_table-stickyHead` : ``
      )}
      ref={(node) => {
        if (node) {
          tableContainerRef.current = node;
          if (tableContainerProps?.ref) {
            //@ts-ignore
            tableContainerProps.ref.current = node;
          }
        }
      }}
      style={{
        maxHeight: isFullScreen
          ? `calc(100vh - ${totalToolbarHeight}px)`
          : undefined,
        ...tableContainerProps?.style,
      }}
    >
      <MRT_Table table={table} />
    </div>
  );
};
