import { useCallback, useEffect, useRef, useState } from 'react';
import { iFrameHost, PropsFromCartRedux } from './BuildOwn';
import { PropsFromScreenManager } from '../../types';
import { useMediaQuery } from 'react-responsive';

const useBuildOwn = (props:PropsFromScreenManager & PropsFromCartRedux) => {
  const {instanceData: { screenManager },ring,footer,setCartImageSuccess,setCartThumbImageSuccess} = props
  const [isIcon, setIsIcon] = useState(true);
  const [viewSrc, setViewSrc] = useState("");
  const [iframeLoadActionCount, setIframeLoadActionCount] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const currentIcon = !isIcon ? "hk-AR" : "hk-image";
  const [image,setImage] = useState<string[]>([]);
  const [thumbImage,setThumbImage] = useState<string[]>([]);
  const [handViewImage,setHandViewImage] = useState<{[key:string]:string}>({});
  const [isSticky, setSticky] = useState(false);
  const byoSliderRoot = useRef<HTMLDivElement>(null);
  const islargeTab = useMediaQuery({ query: '(max-width: 1279px)' });
  useEffect(() => {
    setViewSrc(iFrameHost);
  }, []);

  useEffect(() => {
    const customisation_allowed = ring.extraData.customisation_allowed;
    const fetchData = async () => {
      if (Object.keys(ring.options).length > 0) {
        const {
          Ring_Style,
          Ring_Crown,
          Center_Diamond_Size,
          Ring_Side,
          Diamond_Shape,
          Crown_Color,
          Color,
          Single_First_Metal_Band,
          Double_First_Metal_Band,
          Double_Second_Metal_Band,
          Wedding_Band
        } = ring.options;
        if (footer.styleID && Ring_Crown && Diamond_Shape && Center_Diamond_Size && Ring_Side) {
          const imageSKU = `${Ring_Style}-${Ring_Crown}-${Ring_Side}`;
          // const lowQuality = await getLowQualityRender(Ring_Style, Ring_Crown, Ring_Side);
          setImage([]);
          setThumbImage([]);
          const headColor = customisation_allowed ? Crown_Color : Color;
          const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}` : `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}`;
          const uniqueName = footer.styleID.includes('RP') ? `${footer.styleID}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}` : `${footer.styleID}${Ring_Style}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}${headColor}${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `${Double_Second_Metal_Band?.split('_')[1]}` : ''}${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}`;
          setHandViewImage({
            image: `${process.env.REACT_APP_IMAGE_KEY}/${style}/${headColor}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}/V5/2000/fafafa/hq/${uniqueName}V52000.jpg?${process.env.REACT_APP_CACHING_PARAM}`,
            thumbPath: `${process.env.REACT_APP_IMAGE_KEY}/${style}/${headColor}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}/V5/150/fafafa/hq/${uniqueName}V5150.jpg?${process.env.REACT_APP_CACHING_PARAM}`
          });

          ["V1", "V2", "V3", "V4"].forEach((view: string) => {
            const imagePath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${headColor}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}/${view}/2000/fafafa/hq/${uniqueName}${view}2000.jpg?${process.env.REACT_APP_CACHING_PARAM}`;
            const thumbPath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${headColor}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}/${view}/150/fafafa/hq/${uniqueName}${view}150.jpg?${process.env.REACT_APP_CACHING_PARAM}`;
            setImage((prev: string[]) => {
              return [...prev, imagePath];
            });
            setThumbImage((prev: string[]) => {
              return [...prev, thumbPath];
            });
          });
        }
      }
    }

    fetchData(); // Call the async function immediately

  }, [ring.extraData.customisation_allowed, ring.options['Crown_Color'], ring.options['Ring_Style'], ring.options['Ring_Crown'], ring.options['Diamond_Shape'], ring.options['Center_Diamond_Size'], ring.options['Ring_Side'], ring.options['Metal'], ring.options['Color'], ring.options['Diamond_Type'], ring.options['Wedding_Band'], ring.options['Single_First_Metal_Band'], ring.options['Double_Second_Metal_Band'], ring.options['Double_First_Metal_Band']]);

  useEffect(()=>{
    if(thumbImage.length > 0) {
      setCartImageSuccess([...image]);
    }
  },[image])

  useEffect(()=>{
    if(thumbImage.length > 0){
      setCartThumbImageSuccess([...image]);
    }
  },[thumbImage])

  const handleChangeIcon = useCallback(() => {
    setIsIcon((icon) => !icon);
  }, [setIsIcon]);

  useEffect(() => {
    (screenManager as any).props.setStateManagerData(
      "currentScreen",
      "Select Settings screen"
    );
    (screenManager as any).props.setStateManagerData(
      "currentView",
      "Select Settings Main View"
    );
  }, []);

  useEffect(() => {
    const htmlElement = document.documentElement;
    const bodyHtml = document.body;
    const div = byoSliderRoot.current;
    div ? bodyHtml.classList.add('byo_page_present') : bodyHtml.classList.remove('byo_page_present');
    const handleScroll = () => {
      const scrollTop = htmlElement.scrollTop;
      setSticky(scrollTop > 0);
      const maxScrollTop = 230;
      const clampedScrollTop = Math.min(scrollTop, maxScrollTop);
      if (div) {
        div.style.setProperty('--scroll-offset', `${clampedScrollTop}px`);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      bodyHtml.classList.remove('byo_page_present');
    };
  }, []);

  return {
    img:image,
    thumbImage,
    viewSrc,
    currentIcon,
    handleChangeIcon,
    isIcon,
    iframeLoadActionCount,
    setIframeLoadActionCount,
    islargeTab,
    isSticky,
    byoSliderRoot,
    activeIndex,
    setActiveIndex
  };
};
export default useBuildOwn;
