import React from 'react';
import classes from "./MetalColorTooltip.module.css";
import {CaratType} from "../../types";
import UseMetalColorTooltip from "./useMetalColorTooltip";

const MetalColorTooltip = () => {
  const {
    swatchColor,
    selectedUrl,
    MetalDetails,
    selectedMetal,
    metalCarat,
    selectedCarat,
    handleSelectMetal,
    handleSelectCarat
  } = UseMetalColorTooltip();

  return (
    <>
      <div className={classes.metalColorBody}>
        <div className={classes.metalColorImage}>
          <img
            src={selectedUrl}
            alt="Helzberg Diamonds, a Berkshire Hathaway Company"
          />
        </div>
        <span className={classes.metalColorLabel}>
                    <span>Metal: </span>
                        <span className={classes.metal}>{selectedMetal?.split('-').join(' ')}</span>
                      </span>
        <div className={classes.swatchesContainer}>
          {
            Object.keys(MetalDetails).map((item: string) => {
              return (<img
                src={swatchColor[item]}
                key={item}
                className={`${classes.metalColorDetail} ${selectedMetal === item ? classes.isActive : ''}`}
                onClick={() => handleSelectMetal(item)}
              />)
            })
          }
        </div>
        <hr className={classes.horizontalLine}/>
        <span className={classes.caratLabel}>
          <span>Metal Fineness: </span>
          <span className="carat-label-value">{selectedMetal === 'platinum' ? 'Platinum' : selectedCarat}</span>
        </span>
        {
          selectedMetal === 'platinum' ?
            <div className={classes.platinumContainer}>
              <span>
                {"A platinum setting is most the most secure,\n" +
                  "offering unsurpassed protection for diamonds and gemstones.\n" +
                  "Platinum prongs don't break or crack, which lessens the chance of losing a stone. It's a sound investment for your Diamond or gemstone."}
              </span>
              <a
                href={'https://www.helzberg.com/jewelry-advice/all-about-platinum.html'}
                target={'_blank'}
              >
                {"Learn more"}
              </a>
            </div>
            :
            <div className={classes.caratContainer}>
              {
                metalCarat.length > 0 && metalCarat.map((carat: CaratType, index: number) => (
                  <span
                    key={index}
                    className={`${classes.caratSquare} ${(selectedCarat === carat.id && selectedMetal !== 'platinum') ? classes.isActive : ''} ${selectedMetal === 'platinum' ? 'disabled' : ''}`}
                    onClick={() => handleSelectCarat(carat.id)}
                  >
                      {carat.id}
                    </span>
                ))
              }
            </div>
        }
      </div>

    </>
  )
}

export default MetalColorTooltip;
