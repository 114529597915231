import React, { FC, useEffect, useState } from "react";
import classes from "./Header.module.css";
import logo from "../../Utils/images/name-logo.png";
import logoMobile from "../../Utils/images/icon-logo.png";
import { Link } from "react-router-dom";
import Icon from "../Icons/Icon";
import useHeader from "./useHeader";
import HeadCenterTab from "./HeadCenterTab";
import { RootState } from "../../reducers/RootReducer";
import { connect, ConnectedProps } from "react-redux";
import ExitButton from "../ExitButton";
import DialogBox from "../DialogBox";
import { PropsFromScreenManager } from "../../types";

const mapStateToProps = (state: RootState) => ({
  header: state.header,
  stateManager: state.stateManager,
  ring: state.ring,
  diamond: state.diamond,
  settingFilter: state.settingFilter,
  table: state.table,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromHeaderRedux = ConnectedProps<typeof connector>;

const Header: FC<PropsFromHeaderRedux & PropsFromScreenManager> = (props) => {
  const {
    table,
    settingFilter,
    diamond,
    ring,
    header,
    stateManager,
    instanceData: { screenManager },
    iframeLoadActionCount,
  } = props;
  // const {renderMenuContent, mobileMenu} = useHeader();
  // const {
  //   renderMenuContent,
  //   mobileMenu,
  //   isOpenDialog,
  //   closeDialogBox,
  //   openDialogBox,
  // } = useHeader();

  const handleLogoLink = () => {
    ["rpid", "builder_mode", "design_uuid", "previousPath"].forEach((key) =>
      localStorage.removeItem(key)
    );
    //@ts-ignore
    window.location.href = window.location.origin;
  };

  return (
    <div className={classes.header}>
      <div className={`${classes.container} hk_container`}>
        <div className={classes.logoWrap}>
          {/* <div className={classes.logoLink} onClick={()=>(screenManager as any).changeScreen({ viewName: 'home', id: null })}> */}

          <button
            type={"button"}
            className={classes.logoLink}
            onClick={handleLogoLink}
          >
            <picture className={classes.logo}>
              <source media="(max-width:1079px)" srcSet={logoMobile} />
              <img src={logo} className="w-32" alt="logo" />
            </picture>
          </button>
        </div>
        {header?.tabData && (
          <HeadCenterTab
            tabData={header.tabData}
            instanceData={{ screenManager }}
            isDisabledTab={
              table.isLoading ||
              settingFilter.isProductLoading ||
              diamond.isLoading ||
              ring.isLoading
            }
            iframeLoadActionCount={iframeLoadActionCount}
          />
        )}
        <ExitButton
          instanceData={{ screenManager }}
          previousPath={header.previousPath}
        />
        {/* <div className={classes.link} onClick={openDialogBox}>
                <Icon name={'hk-exit'} className={classes.icon} />
                <span className={classes.linkText}>Data</span>
              </div> */}
        {/* <div className={classes.helpWrap}>
                    {renderMenuContent()}
                    <div
                        className={`${classes.helpInner} ${
                            mobileMenu ? classes.active : ""
                        }`}
                    >
                        <Link className={classes.link} to="/help">
                            <Icon name="hk_help" className={classes.icon}/>
                            <span className={classes.linkText}>Help</span>
                        </Link>
                        <div className={classes.link}>
                            <Icon name={"hk-exit"} className={classes.icon}/>
                            <span className={classes.linkText}>Exit</span>
                        </div>
                    </div>
                </div>*/}
      </div>
    </div>
    // {/* <DialogBox
    //   isOpen={isOpenDialog}
    //   closeModal={closeDialogBox}
    //   title={'Memory Manager'}
    // >
    //   <div>
    //     {Object.keys(stateManager.options).map((key) => (
    //       <div key={key}>
    //         <strong>{key}:</strong>{' '}
    //         {JSON.stringify(stateManager.options[key])}
    //       </div>
    //     ))}
    //   </div>
    // </DialogBox> */}
  );
};

export default connector(Header);
