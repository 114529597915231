import React from "react";
import { MRT_Column, MRT_RowData, MRT_TableInstance } from "../../types";

interface Props<TData extends MRT_RowData> {
  column: MRT_Column<TData>;
  table: MRT_TableInstance<TData>;
}
export const MRT_ColumnPinningButtons = <TData extends MRT_RowData>({
  column,
  table,
  ...rest
}: Props<TData>) => {
  const {
    options: {
      icons: { PushPinIcon },
      localization,
    },
  } = table;

  const handlePinColumn = (pinDirection: "left" | "right" | false) => {
    column.pin(pinDirection);
  };
  return (
    <div className="headless_columnpin">
      {column.getIsPinned() ? (
              <div className="headless_tooltip_bottom" data-tip={localization.unpin}>
                <button
                    className={"headless_btn_transparent"}
                    onClick={() => handlePinColumn(false)}
                    {...rest}
                >
                  <PushPinIcon className={"h-6 w-6"} />
                </button>
              </div>
      ) : (
        <>
          <div className="headless_tooltip_bottom" data-tip={localization.pinToLeft}>
            <button
                className={"headless_btn_transparent"}
                onClick={() => handlePinColumn("left")}
                {...rest}
            >
              <PushPinIcon className={"h-6 w-6"} />
            </button>
          </div>
          <div className="tooltip tooltip-bottom" data-tip={localization.pinToRight}>
            <button
                className={"headless_btn_transparent"}
                onClick={() => handlePinColumn("right")}
                {...rest}
            >
              <PushPinIcon className={"h-6 w-6"} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
