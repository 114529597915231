import Screen from '../../Screen';
import React from 'react';
import { PropsFromScreenManager } from '../../types';
import Header from '../Header';
import StickyFooter from '../StickyFooter';
import StartWithSetting from './StartWithSetting';

class StartWithSettingScreen extends Screen {
  constructor(props: PropsFromScreenManager) {
    super(props);
  }

  render() {
    return (
      <>
        <StartWithSetting instanceData={this.props.instanceData} />
      </>
    );
  }
}

export default StartWithSettingScreen;
