import React,{ useMemo, useState } from "react";
import { MRT_ShowHideColumnsMenuItems } from "./MRT_ShowHideColumnsMenuItems";
import { MRT_Column, MRT_RowData, MRT_TableInstance } from "../../types";
import { getDefaultColumnOrderIds } from "../../column.utils";

interface Props<TData extends MRT_RowData> {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  table: MRT_TableInstance<TData>;
}
export const MRT_ShowHideColumnsMenu = <TData extends MRT_RowData>({
  anchorEl,
  setAnchorEl,
  table,
  ...rest
}: Props<TData>) => {
  const {
    getAllColumns,
    getAllLeafColumns,
    getCenterLeafColumns,
    getIsAllColumnsVisible,
    getIsSomeColumnsPinned,
    getIsSomeColumnsVisible,
    getLeftLeafColumns,
    getRightLeafColumns,
    getState,
    toggleAllColumnsVisible,
    options: {
      enableColumnOrdering,
      enableHiding,
      enablePinning,
      localization,
    },
  } = table;
  const { density, columnOrder, columnPinning } = getState();
  const hideAllColumns = () => {
    getAllLeafColumns()
      .filter((col) => col.columnDef.enableHiding !== false)
      .forEach((col) => col.toggleVisibility(false));
  };

  const allColumns = useMemo(() => {
    const columns = getAllColumns();
    if (
      columnOrder.length > 0 &&
      !columns.some((col) => col.columnDef.columnDefType === "group")
    ) {
      return [
        ...getLeftLeafColumns(),
        ...Array.from(new Set(columnOrder)).map((colId) =>
          getCenterLeafColumns().find((col) => col?.id === colId)
        ),
        ...getRightLeafColumns(),
      ].filter(Boolean);
    }
    return columns;
  }, [
    columnOrder,
    columnPinning,
    getAllColumns(),
    getCenterLeafColumns(),
    getLeftLeafColumns(),
    getRightLeafColumns(),
  ]) as MRT_Column<TData>[];

  const [hoveredColumn, setHoveredColumn] = useState<MRT_Column<TData> | null>(
    null
  );

  return (
    <>
      <div className={"headless_showHide-column"}>
        {enableHiding && (
          <button
            disabled={!getIsSomeColumnsVisible()}
            onClick={hideAllColumns}
            className="headless_showHide-column-btn"
          >
            {localization.hideAll.toUpperCase()}
          </button>
        )}
        {enableColumnOrdering && (
          <button
            disabled={!getIsSomeColumnsVisible()}
            onClick={() =>
              table.setColumnOrder(getDefaultColumnOrderIds(table.options))
            }
            className="headless_showHide-column-btn"
          >
            {localization.resetOrder.toUpperCase()}
          </button>
        )}
        {enablePinning && (
          <button
            disabled={!getIsSomeColumnsPinned()}
            onClick={() => table.resetColumnPinning(true)}
            className="headless_showHide-column-btn"
          >
            {localization.unpinAll.toUpperCase()}
          </button>
        )}
        {enableHiding && (
          <button
            disabled={getIsAllColumnsVisible()}
            onClick={() => toggleAllColumnsVisible(true)}
            className="headless_showHide-column-btn"
          >
            {localization.showAll.toUpperCase()}
          </button>
        )}
      </div>
      <div className="headless_SH-column-div" aria-hidden="true">
        <div className="headless_SH-column-child" />
      </div>

      {allColumns.map((column, index) => (
        <MRT_ShowHideColumnsMenuItems
          allColumns={allColumns}
          column={column}
          hoveredColumn={hoveredColumn}
          key={`${index}-${column?.id}`}
          setHoveredColumn={setHoveredColumn}
          table={table}
        />
      ))}
    </>
  );
};
