export default {
  BUILDER_MODE_KEY: 'builder_mode',
  DEFAULT_RPID: '000000',
  EDIT: 'edit',
  BUILD: 'build',
  DIAMOND: 'diamond',
  SETTING: 'setting',
  DYO: 'dyo',
  DYO_STYLE_ID : 'RB001'
};
