export const getExclusionOptions = (exclusionMatrix : { [key: string]: string }[], key:string,code:string,exclusionMatrixOption:{[key: string]: string[]},options: {[key: string]: string}) => {
  const ringExclusionMatrix: { [key: string]: string[] } = {};
  const selectedExclusionData = exclusionMatrix.filter((item) => item[key] === code);
  Object.keys(exclusionMatrixOption).forEach(option => {
    const otherExclusionOption = Object.keys(exclusionMatrixOption).filter(item => item !== key).filter(item => item !== option);
    const filteredData = selectedExclusionData.filter(data => {
      return otherExclusionOption.every(k => {
        return data[k] === {...options, [key]: code}[k];
      });
    });
    ringExclusionMatrix[option] = filteredData.map(data => data[option]);
  });
  return ringExclusionMatrix
}
export const exclusionMatrixData = async (styleId : string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PDP_OPTIONS_KEY}?ring_builder=${styleId}&exclusion=true`);
    if (!response.ok) {
      console.log(new Error(`HTTP error! Status: ${response.status}`));
    }
    return await response.json();
  } catch (e) {
    console.log(e)
  }
}
