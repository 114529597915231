import React from "react";
import { getCommonCellStyles } from "../../column.utils";
import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  footer: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const MRT_TableFooterCell = <TData extends MRT_RowData>({
  footer,
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: { layoutMode, muiTableFooterCellProps },
  } = table;
  const { density } = getState();
  const { column } = footer;
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const mTableFooterCellProps =
    muiTableFooterCellProps instanceof Function
      ? muiTableFooterCellProps({ column, table })
      : muiTableFooterCellProps;

  const mcTableFooterCellProps =
    columnDef.muiTableFooterCellProps instanceof Function
      ? columnDef.muiTableFooterCellProps({ column, table })
      : columnDef.muiTableFooterCellProps;

  const tableCellProps = {
    ...mTableFooterCellProps,
    ...mcTableFooterCellProps,
  };
  const theme = "light";
  return (
    <td
      className={classNames(
        `headless_tfoot-td`,
        columnDefType === "group" ? `headless_justiText-center` : `headless_textLeft`,
        layoutMode === "grid" ? `headless_grid` : `headless_table-cell`,
        density === "compact"
          ? "headless_p-2"
          : density === "comfortable"
          ? "headless_p-4"
          : "headless_p-6",
        column.getIsPinned() && columnDefType !== "group" ? `headless_z-2` : `headless_z-1`
      )}
      colSpan={footer.colSpan}
      {...tableCellProps}
      style={{
        ...getCommonCellStyles({
          column,
          table,
        }),
      }}
    >
      <>
        {footer.isPlaceholder
          ? null
          : (columnDef.Footer instanceof Function
              ? columnDef.Footer?.({
                  column,
                  footer,
                  table,
                })
              : columnDef.Footer) ??
            columnDef.footer ??
            null}
      </>
    </td>
  );
};
