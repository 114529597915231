import {setDiamondDetailsLoadingType, setSelectedDiamondFailType} from "../types";

export const getDiamondCode = async (diamondCode:{[key:string]:string},setSelectedDiamondLoading:setDiamondDetailsLoadingType,setSelectedDiamondFail:setSelectedDiamondFailType) => {
  const queryParams = new URLSearchParams(diamondCode as Record<string, string>).toString();
  setSelectedDiamondLoading();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SELECTED_DIAMOND_OPTIONS}?${queryParams}`
    );
    if (!response.ok) {
      setSelectedDiamondFail(
        new Error(`HTTP error! Status: ${response.status}`)
      );
    }
   return await response.json();

  } catch (e) {
    setSelectedDiamondFail(e);
  }
}
