import React, { Fragment } from "react";
import {
  GCAL,
  GIA,
  HKDiamondDetail1,
  HKDiamondDetail2,
  HKDiamondDetail3,
  HKDiamondDetail4,
  HKDiamondDetail5,
  IGI,
} from "../Images";
import DiamondDetail from "../Table/DiamondDetail";
import Button from "../Button/Button";
import classes from "./DiamondDetailsPage.module.css";
import DiamondOtherDetails from "../DiamondOtherDetails";
import MainImageSlider from "../MainImageSlider";
import useDiamondDetailsPage from "./useDiamondDetailsPage";
import DialogBox from "../DialogBox/DialogBox";
import { RootState } from "../../reducers/RootReducer";
import {
  setInitialDiamondOption,
  setDiamondDetailsSuccess,
  setDiamondDetailsLoading,
  setDiamondDetailsFail,
  setBackToSelectDiamondPage,
} from "../../action/DiamondAction";
import { setFooterToggle, setIsShowFooter } from "../../action/FooterAction";
import { setIsCartLoading } from "../../action/CartAction";
import {
  setInitialOption,
  setBackDiamondDetails,
  setJourneyStartedFrom,
  setSelectedDiamondLoading,
  setSelectedDiamondFail,
  setSelectedDiamondData,
  setExclusionOption,
} from "../../action/RingAction";
import { connect, ConnectedProps } from "react-redux";
import Loader from "../Loader/Loader";
import { setDiamondTabIconTrue } from "../../action/HeaderAction";
import { ToLocalStringConvert } from "../../Utils/toLocalStringConvert";
import { PropsFromScreenManager } from "../../types";

import { recentDataSuccess } from "../../action/TableAction";
import Header from "../Header/Header";
import StickyFooter from "../StickyFooter/StickyFooter";
import dialogTitleImg from "../../Utils/images/hk-ring.png";
const mapStateToProps = (state: RootState) => ({
  diamond: state.diamond,
  header: state.header,
  ring: state.ring,
  filter: state.filter,
  footer: state.footer,
});

const mapDispatchToProps = {
  setDiamondTabIconTrue,
  setDiamondDetailsSuccess,
  setDiamondDetailsLoading,
  setDiamondDetailsFail,
  setInitialDiamondOption,
  setFooterToggle,
  setInitialOption,
  setBackDiamondDetails,
  recentDataSuccess,
  setIsShowFooter,
  setJourneyStartedFrom,
  setSelectedDiamondLoading,
  setSelectedDiamondFail,
  setSelectedDiamondData,
  setBackToSelectDiamondPage,
  setExclusionOption,
  setIsCartLoading,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromDiamondDetailsRedux = ConnectedProps<typeof connector>;
const DiamondDetailsPage: React.FC<
  PropsFromDiamondDetailsRedux & PropsFromScreenManager
> = (props) => {
  const { diamond, instanceData } = props;
  const img: string[] = [];
  // HKDiamondDetail1, HKDiamondDetail2, HKDiamondDetail3, HKDiamondDetail4, HKDiamondDetail5
  const {
    navigate,
    isOpenDialog,
    closeDialogBox,
    handleSelectThisDiamond,
    handleBackDiamondSearch,
    dialogDetails,
  } = useDiamondDetailsPage(props);

  if (diamond.isLoading) {
    return (
      <div className={"loading-details"}>
        <Loader position="absolute" />
      </div>
    );
  }
  if (diamond.error) {
    return <div>Error...</div>;
  }
  const {
    certification,
    certificate_link,
    video_link,
    image_link,
    carat,
    shape,
    color,
    clarity,
    cut,
    source,
    b2c_price: diamond_b2c_price,
  } = diamond.details;

  if (image_link && (image_link !== null || image_link !== undefined)) {
    img.unshift(image_link as string);
  }
  return (
    <>
      <Header instanceData={instanceData} />
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={`${classes.leftSideContainer}`}>
            <div className={`${classes.sliderContainer} sliderRef`}>
              <MainImageSlider
                viewSrc={
                  video_link && video_link !== "null"
                    ? (video_link as string)
                    : ""
                }
                is360={video_link && video_link !== "null" ? true : false}
                img={img}
                isDiamondDetail={true}
                isIcon={false}
                dots={true}
                isShowGIA={true}
                certification={certificate_link as string}
                certificationName={certification as string}
              />
            </div>
          </div>

          <div className={classes.rightSideContainer}>
            <div className={classes.content}>
              <DiamondDetail
                carat={`${carat ? `${carat} ct.` : ""}`}
                color={color}
                clarity={clarity}
                cut={cut}
                title={`${carat ? `${carat} ct.` : ""} ${
                  shape ? (shape as string) : ""
                } ${source ? `${source} Diamond` : ""} `}
                price={ToLocalStringConvert(
                  diamond_b2c_price ? +diamond_b2c_price : 0
                )}
              />
              <div className={classes.buttonSection}>
                <Button
                  type={"button"}
                  className={classes.button}
                  buttonType={"btnPrimary"}
                  onClick={handleSelectThisDiamond}
                  aria-label={"Select This Diamond"}
                >
                  {/*<Icon name={"hk-viewDiamond"} />*/}
                  <span className="text-padding-top-25">
                    Select This Diamond
                  </span>
                </Button>
                <Button
                  type={"button"}
                  buttonType={"btnSecondary"}
                  className={classes.backButton}
                  onClick={() => handleBackDiamondSearch()}
                  aria-label={"Back to Diamond Search"}
                >
                  <div className={classes.navigate}>
                    {/*<Icon name={"hk_chevron_left"} />*/}
                    <span className="text-padding-top-7">
                      Back to Diamond Search
                    </span>
                  </div>
                </Button>
              </div>
              <div className={classes.items_wrap}>
                <DiamondOtherDetails diamondDetails={diamond.details} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogBox
        isOpen={isOpenDialog}
        isIcon={true}
        closeModal={closeDialogBox}
        titleClassName={classes.dialogBoxTitle}
        title={""}
      >
        <div className={classes.dialogSetting}>
          <img
            className={classes.dialog_img}
            src={dialogTitleImg}
            alt={"Settings"}
          />
          <p className={classes.dialog_subtitle}>Our Settings</p>
          <p className={classes.dialog_text}>
            Design your own setting or choose <br /> a pre-crafted setting.
          </p>
          <div className={classes.buttonGroup}>
            {dialogDetails.map((detail, index) => {
              return (
                <Fragment key={index}>
                  <Button
                    type={"button"}
                    buttonType={"btnSecondary"}
                    className={classes.btnSetting}
                    aria-label={detail.title}
                    isDisabled={
                      detail.title === "Design Your Own Setting" &&
                      (diamond.details.shape as string)?.toLowerCase() ===
                        "marquise" &&
                      diamond.details &&
                      diamond.details.carat &&
                      +diamond.details.carat >= 1.75
                        ? true
                        : false
                    }
                    onClick={() => {
                      detail.handleClick();
                    }}
                  >
                    <span>{detail.title}</span>
                  </Button>
                </Fragment>
              );
            })}
          </div>
        </div>
      </DialogBox>
      <StickyFooter instanceData={instanceData} />
    </>
  );
};

export default connector(DiamondDetailsPage);
