import React,{ useEffect, DragEvent } from "react";
import { MRT_RowData, MRT_TableInstance } from "../../types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}
export const MRT_ToolbarDropZone = <TData extends MRT_RowData>({
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: { enableGrouping, localization },
    setHoveredColumn,
    setShowToolbarDropZone,
  } = table;

  const { draggingColumn, hoveredColumn, grouping, showToolbarDropZone } =
    getState();

  const handleDragEnter = (_event: DragEvent<HTMLDivElement>) => {
    setHoveredColumn({ id: "drop-zone" });
  };

  useEffect(() => {
    if (table.options.state?.showToolbarDropZone !== undefined) {
      setShowToolbarDropZone(
        !!enableGrouping &&
          !!draggingColumn &&
          draggingColumn.columnDef.enableGrouping !== false &&
          !grouping.includes(draggingColumn.id)
      );
    }
  }, [enableGrouping, draggingColumn, grouping]);

  return (
    <>
      {showToolbarDropZone ? (
        <div
          className={classNames(
            `headless_dropzone `,
            hoveredColumn?.id === "drop-zone"
              ? `headless_bg-primary/20`
              : "headless_bg-primary/10"
          )}
          onDragEnter={handleDragEnter}
        >
          <h3 className={"italic"}>
            {localization.dropToGroupBy.replace(
              "{column}",
              draggingColumn?.columnDef?.header ?? ""
            )}
          </h3>
        </div>
      ) : null}
    </>
  );
};
